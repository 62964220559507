import { Fragment, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const Logout = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_API}/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          STORE_CODE: authCtx.getStoreCode(),
          Authorization: `Bearer ${authCtx.getToken()}`,
        },
      });
      authCtx.onLogout();
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  }, [authCtx, navigate]);

  useEffect(() => {
    logout();
  }, [logout]);
  return <Fragment></Fragment>;
};

export default Logout;
