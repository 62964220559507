import React, { Fragment, useCallback, useContext, useState } from "react";
import { Grid, Form, Label, Input, Button, Icon } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import { getCustomerByIdentifierApi } from "../../api/api";
import UpdateCustomerForm from "./UpdateCustomerForm";
import Utils from "../../utils/Utils";
import ErrorMessage from "../common/ErrorMessage";

const SearchCustomerForm = (props) => {
  const authCtx = useContext(AuthContext);
  const [customer, setCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
  };

  const fetchCustomer = useCallback(
    async (id) => {
      try {
        const response = await getCustomerByIdentifierApi(
          id,
          true,
          true,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );
        const customer = Utils.toCustomer(response);
        setCustomer(customer);
      } catch (error) {
        setCustomer(null);
        handleFormError("Customer not found");
        console.log("error:", error);
      }
    },
    [authCtx]
  );

  const submitBookingHandler = (event) => {
    event.preventDefault();
    setErrorMessage("");

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    if (!data.mobileNumber) {
      handleFormError("Enter mobile number");
      return;
    }

    if (!data.mobileNumber.startsWith("63")) {
      handleFormError("Mobile number should start with '63'");
      return;
    }

    if (data.mobileNumber.length !== 12) {
      handleFormError("Mobile Number should be 12 digits");
      return;
    }

    fetchCustomer(data.mobileNumber);
  };

  const invokeSearch = () => {
    fetchCustomer(customer.mobileNumber);
  };

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={16}>
          {errorMessage && (
            <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
          )}
          <Form onSubmit={submitBookingHandler}>
            <fieldset className="fieldset">
              <legend>Search Customer</legend>
              <Grid>
                <Grid.Row className="marginTop20 marginBottom20">
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={2}>
                    <Label
                      htmlFor="customerMobileNo"
                      basic
                      className="borderNone padding0"
                    >
                      Enter Customer Mobile No.
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Input
                      id="customerMobileNo"
                      type="number"
                      placeholder="639xxxxxxxxx"
                      required
                      name="mobileNumber"
                      min="0"
                      onChange={(event) => {
                        setCustomer(null);
                        setErrorMessage("");
                      }}
                    ></Input>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Button type="submit" primary>
                      <Icon name="search" />
                      SEARCH
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
              </Grid>
            </fieldset>
          </Form>
          {customer && (
            <UpdateCustomerForm
              customer={customer}
              invokeSearch={invokeSearch}
              customerStatuses={props.customerStatuses}
              customerTypes={props.customerTypes}
            ></UpdateCustomerForm>
          )}
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default SearchCustomerForm;
