import React, {
  Fragment,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import LockersTable from "../components/lockers/LockersTable";
import Utils from "../utils/Utils";
import AuthContext from "../store/auth-context";
import { getLockersApi } from "../api/api";
import LockerModal from "../components/lockers/LockerModal";

const Lockers = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allLockers, setAllLockers] = useState([]);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchLockers = useCallback(async () => {
    try {
      const response = await getLockersApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedLockers = [];
      for (const key in response) {
        loadedLockers.push(Utils.toRoom(response[key]));
      }
      setAllLockers(loadedLockers);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchLockers();
    }
  }, [fetchLockers, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <LockersTable lockers={allLockers}></LockersTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <LockerModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD}
        />
      )}
    </Fragment>
  );
};

export default Lockers;
