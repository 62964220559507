import { Fragment, useState } from "react";
import { Table } from "semantic-ui-react";
import Utils from "../../utils/Utils";
import AdminsModal from "./AdminsModal";

const AdminsTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelecterUser] = useState("");

  const onClick = (admin) => {
    setIsModalOpen(true);
    setSelecterUser(admin);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <div style={{ maxHeight: "800px", overflow: "auto" }}>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Admin</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Enable Access
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Roles</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!props.admins || props.admins.length === 0 ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={5}>
                  No admins
                </Table.Cell>
              </Table.Row>
            ) : (
              props.admins.map((admin) => {
                return (
                  <Table.Row key={admin.id}>
                    <Table.Cell
                      className="pointer-on-hover"
                      onClick={() => onClick(admin)}
                      textAlign="center"
                    >
                      {admin.username}
                    </Table.Cell>
                    <Table.Cell
                      className={admin.enableAccess ? "green" : "red"}
                      textAlign="center"
                    >
                      {admin.enableAccess ? "Yes" : "No"}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{admin.roles}</Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {isModalOpen && (
        <AdminsModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedUser={selectedUser}
          eventName={Utils.EVENT_UPDATE}
        />
      )}
    </Fragment>
  );
};

export default AdminsTable;
