import { useState, useContext } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  Label,
  Input,
  Grid,
  Divider,
} from "semantic-ui-react";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import AuditInfo from "../common/AuditInfo";
import { updateProductApi, addProductApi } from "../../api/api";

const ProductsModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [name, setName] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedProduct.name : ""
  );
  const [price, setPrice] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedProduct.price : ""
  );
  const [productType, setProductType] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedProduct.type : ""
  );
  const [isAvailable, setIsAvailable] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedProduct.available
      : true
  );

  const [hasUpdates, setHasUpdates] = useState(false);
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalProductForm");
    if (form) {
      const formData = new FormData(form);

      const updateData = {};
      for (let [name, value] of formData.entries()) {
        updateData[name] = value;
      }

      if (!updateData.name) {
        handleFormError("Name is required and should be unique.");
        return;
      }

      if (!updateData.price) {
        handleFormError("Price is required.");
        return;
      }

      updateData["available"] = "true"; //isAvailable ? "true" : "false";

      updateData["type"] = productType;

      updateData.storeCode = authCtx.getStoreCode();

      updateProductApi(
        props.selectedProduct.id,
        JSON.stringify(updateData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Product details are updated.");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      console.error("Unable to find form");
      handleFormError("Something went wrong.");
    }
  };

  const handleAddButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalProductForm");
    if (form) {
      const formData = new FormData(form);

      const data = {};
      for (let [name, value] of formData.entries()) {
        data[name] = value;
      }

      if (!data.name) {
        handleFormError("Name is required and should be unique.");
        return;
      }

      if (!data.price) {
        handleFormError("Price is required.");
        return;
      }

      data["available"] = "true"; //isAvailable ? "true" : "false";

      data.storeCode = authCtx.getStoreCode();

      addProductApi(
        JSON.stringify(data),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("New product is added.");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      console.error("Unable to find form");
      handleFormError("Something went wrong.");
    }
  };

  return (
    <Modal
      closeIcon
      size="tiny"
      onClose={handleOnCloseModal}
      open={props.isModalOpen}
    >
      <Modal.Header>
        {props.eventName === Utils.EVENT_UPDATE &&
          `Update Product Details: ${props.selectedProduct.name}`}
        {props.eventName === Utils.EVENT_ADD && `Add Product`}
      </Modal.Header>
      <Modal.Content>
        {modalFormErrorMessage && (
          <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
        )}
        {modalFormSuccessMessage && (
          <SuccessMessage
            successMessage={modalFormSuccessMessage}
          ></SuccessMessage>
        )}
        <Form size="small" key="small" id="modalProductForm">
          <Grid columns={2}>
            <Grid.Column width={4} className="marginTop10">
              <Label
                basic
                htmlFor="modalProductName"
                className="borderNone paddingRight0"
              >
                Name
              </Label>
            </Grid.Column>
            <Grid.Column width={12} className="paddingTop20">
              <Input
                id="modalProductName"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              ></Input>
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            <Grid.Column width={4} className="marginTop10">
              <Label
                basic
                htmlFor="modalProductPrice"
                className="borderNone paddingRight0"
              >
                Price
              </Label>
            </Grid.Column>
            <Grid.Column width={12} className="paddingTop20">
              <Input
                id="modalProductPrice"
                name="price"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                type="number"
                required
              ></Input>
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            <Grid.Column width={4} className="marginTop10">
              <Label
                basic
                htmlFor="modalProductType"
                className="borderNone paddingRight0"
              >
                Type
              </Label>
            </Grid.Column>
            <Grid.Column width={12} className="paddingTop20">
              <select
                id="modalProductType"
                name="type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                style={{ width: "175px" }}
              >
                {props.productTypes &&
                  props.productTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
              </select>
            </Grid.Column>
          </Grid>
          <Divider hidden fitted />
        </Form>
        {props.eventName === Utils.EVENT_UPDATE && (
          <AuditInfo resource={props.selectedProduct}></AuditInfo>
        )}
      </Modal.Content>
      <Modal.Actions>
        {props.eventName === Utils.EVENT_UPDATE && (
          <Button color="blue" onClick={handleUpdateButton}>
            <Icon name="pencil" /> UPDATE
          </Button>
        )}
        {props.eventName === Utils.EVENT_ADD && (
          <Button color="blue" onClick={handleAddButton}>
            <Icon name="food" /> SUBMIT
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ProductsModal;
