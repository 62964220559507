import React, { Fragment, useState } from "react";
import { Table } from "semantic-ui-react";
import ServicesModal from "./ServicesModal";
import Utils from "../../utils/Utils";

const ServicesTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState([]);

  const onClick = (service) => {
    setIsModalOpen(true);
    setSelectedService(service);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <div style={{ maxHeight: "800px", overflow: "auto" }}>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Price (PHP)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Duration (minutes)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Cleaning Time (minutes)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!props.services || props.services.length === 0 ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={5}>
                  No services created.
                </Table.Cell>
              </Table.Row>
            ) : (
              props.services.map((service) => {
                return (
                  <Table.Row key={service.id}>
                    <Table.Cell
                      className="pointer-on-hover"
                      onClick={() => onClick(service)}
                      textAlign="center"
                    >
                      {service.name}
                    </Table.Cell>
                    <Table.Cell
                      className={service.available ? "green" : "red"}
                      textAlign="center"
                    >
                      {service.available ? "Yes" : "No"}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{service.price}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {service.duration ? service.duration : "-"}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {service.breakDuration ? service.breakDuration : "-"}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{service.type}</Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {isModalOpen && (
        <ServicesModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedService={selectedService}
          eventName={Utils.EVENT_UPDATE}
          serviceOfferedTypes={props.serviceOfferedTypes}
          availablePromos={props.availablePromos}
          availableRooms={props.availableRooms}
        />
      )}
    </Fragment>
  );
};

export default ServicesTable;
