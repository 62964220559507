import React, { Fragment, Component } from "react";
import { Grid, Menu } from "semantic-ui-react";

import ReportForm from "../components/report/ReportForm";
import CustomerReportForm from "../components/report/CustomerReportForm";
import CommissionReportForm from "../components/report/CommissionReportForm";
import TherapistStatisticsReportForm from "../components/report/TherapistStatisticsReportForm";

export default class Reports extends Component {
  state = { activeItem: "Daily Appointments" };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;

    let activeComponent;
    if (activeItem === "Customers") {
      activeComponent = <CustomerReportForm />;
    } else if (activeItem === "Daily Appointments") {
      activeComponent = <ReportForm />;
    } else if (activeItem === "Therapist Commissions") {
      activeComponent = <CommissionReportForm />;
    } else if (activeItem === "Therapist Statistics") {
      activeComponent = <TherapistStatisticsReportForm />;
    }

    return (
      <Fragment>
        <Grid>
          <Grid.Column width={2}>
            <Menu fluid vertical tabular>
              <Menu.Item
                name="Daily Appointments"
                active={activeItem === "Daily Appointments"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="Customers"
                active={activeItem === "Customers"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="Therapist Commissions"
                active={activeItem === "Therapist Commissions"}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="Therapist Statistics"
                active={activeItem === "Therapist Statistics"}
                onClick={this.handleItemClick}
              />
            </Menu>
          </Grid.Column>
          <Grid.Column width={14}>{activeComponent}</Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}
