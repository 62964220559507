import React, { Fragment } from "react";
import Login from "../components/login/Login";

const Landing = () => {
  return (
    <Fragment>
      <Login />
    </Fragment>
  );
};

export default Landing;
