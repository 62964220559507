import { useState, useEffect, useContext, Fragment } from "react";
import {
  Grid,
  Form,
  Input,
  Label,
  Button,
  Divider,
  TextArea,
  Checkbox,
  Popup,
  Icon,
} from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import { createAppointmentApi } from "../../api/api";
import CustomerStats from "./CustomerStats";

const AppointmentForm = (props) => {
  const authCtx = useContext(AuthContext);
  const [mobileNumber, setMobileNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerFound, setCustomerFound] = useState(false);
  const [customerPoints, setCustomerPoints] = useState("");
  const [customerStatus, setCustomerStatus] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [customerVisitCount, setCustomerVisitCount] = useState("");
  const [customerCancelCount, setCustomerCancelCount] = useState("");

  const [searchCustomerText, setSearchCustomerText] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [pointsToRedeem, setPointsToRedeem] = useState("");
  const [promoId, setPromoId] = useState("");

  const [selectedStartTime, setSelectedStartTime] = useState("14:00");
  const [startDate, setStartDate] = useState(Utils.getDateToday());
  const [createdDate, setCreatedDate] = useState(Utils.getDateToday());
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedLocker, setSelectedLocker] = useState("");

  const [hasCheckInPercentCharge, setHasCheckInPercentCharge] = useState(false);
  const [checkInPercentChargeAmount, setCheckInPercentChargeAmount] =
    useState(0);
  const [basePaymentAmount, setBasePaymentAmount] = useState(0);

  const [therapistRequested, setTherapistRequested] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState(
    props.isMassageAppointment ? "RESERVED" : "CHECKED_IN"
  );

  let isCheckedInStatus = appointmentStatus === "CHECKED_IN";

  const submitBookingHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const bookingData = Object.fromEntries(formData.entries());

    if (!bookingData.mobileNumber) {
      props.handleFormError("Customer > Customer Mobile No. is required");
      return;
    }

    if (!bookingData.mobileNumber.startsWith("63")) {
      props.handleFormError(
        "Customer > Customer Mobile No. should start with '63'"
      );
      return;
    }

    if (bookingData.mobileNumber.length !== 12) {
      props.handleFormError(
        "Customer > Customer Mobile No. should be 12 digits"
      );
      return;
    }

    if (!customerName) {
      props.handleFormError("Customer > Customer Name is required");
      return;
    }

    const containsInvalidCharacters = Utils.INVALID_NAME_CHARACTERS.some(
      (char) => customerName.includes(char)
    );

    if (containsInvalidCharacters) {
      props.handleFormError(
        `Customer > Customer Name contains invalid character. These characters ${Utils.INVALID_NAME_CHARACTERS.join(
          " "
        )} are not allowed`
      );
      return;
    }

    if (!bookingData.startTime) {
      props.handleFormError("Service > Time is required");
      return;
    }

    if (
      props.isMassageAppointment &&
      (!bookingData.roomId || !bookingData.staffId)
    ) {
      props.handleFormError(
        "Service > Therapist and Room are required when creating appointment"
      );
      return;
    }

    if (bookingData.status === "CHECKED_IN" && !bookingData.lockerId) {
      props.handleFormError("Service > Locker is required when CHECKING IN");
      return;
    }

    //name
    bookingData.name = customerName;

    //startDate
    bookingData.startDate = startDate;

    //serviceIds
    bookingData.serviceIds = [bookingData.serviceId];

    //roomId
    if (!bookingData.roomId) {
      bookingData.roomId = null;
    }

    //lockerId
    if (!bookingData.lockerId) {
      bookingData.lockerId = null;
    }

    //staffId
    if (!bookingData.staffId) {
      bookingData.staffId = null;
    }

    //therapistRequested
    bookingData.staffRequested = therapistRequested;

    //promoId
    if (!bookingData.promoId) {
      bookingData.promoId = null;
    }

    //note
    if (!bookingData.note) {
      bookingData.note = null;
    }

    //pointsToRedeem
    if (!bookingData.pointsToRedeem) {
      bookingData.pointsToRedeem = null;
    }

    if (bookingData.createdDate === bookingData.startDate) {
      bookingData.createdDate = null;
    }

    bookingData.appointmentCreationPayment = {
      amount: paymentAmount,
      modeOfPayment: bookingData["modeOfPayment"],
    };

    bookingData.hasCheckInPercentCharge = hasCheckInPercentCharge;
    bookingData.checkInPercentChargeAmount = checkInPercentChargeAmount;

    const notNeededFields = ["serviceId", "paymentAmount", "modeOfPayment"];

    for (const field of notNeededFields) {
      delete bookingData[field];
    }

    bookingData.storeCode = authCtx.getStoreCode();

    createAppointmentApi(
      JSON.stringify(bookingData),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log("Error:", error);
        props.handleFormError(error.message);
      });
  };

  const handleChangeService = (e) => {
    const serviceId = e.target.value;
    const service = props.availableServices.find(
      (service) => service.id === serviceId
    );
    setSelectedService(service);
    props.fetchAvailableResources({
      startTime: selectedStartTime,
      startDate: startDate,
      appointmentId: "",
      serviceId: serviceId,
    });
  };

  const handleStartTimeOnChange = (event) => {
    const startTime = event.target.value;
    props.fetchAvailableResources({
      startTime: startTime,
      startDate: startDate,
      appointmentId: "",
      serviceId: selectedService.id,
    });
    setSelectedStartTime(startTime);
  };

  const handleStartDateChange = (event) => {
    const startDate = event.target.value;
    setStartDate(startDate);
    props.fetchAppointmentsByDate(startDate);
    props.fetchAvailableResources({
      startTime: selectedStartTime,
      startDate: startDate,
      appointmentId: "",
      serviceId: selectedService.id,
    });
    if (typeof props.fetchTherapists === "function") {
      props.fetchTherapists(null);
    }
    if (typeof props.setShowAllTherapists === "function") {
      props.setShowAllTherapists(true);
    }
  };

  let statusStyle = {};
  switch (customerStatus) {
    case "ACTIVE":
      statusStyle = { color: "green", fontWeight: "bold" };
      break;
    case "INACTIVE":
      statusStyle = { color: "red", fontWeight: "bold" };
      break;
    default:
      break;
  }

  let customerTypeStyle = {};
  switch (customerType) {
    case "FOH":
      customerTypeStyle = { color: "orange", fontWeight: "bold" };
      break;
    case "DNA_ADMIN":
      customerTypeStyle = { color: "orange", fontWeight: "bold" };
      break;
    case "VIP":
      customerTypeStyle = { color: "purple", fontWeight: "bold" };
      break;
    default:
      customerTypeStyle = { color: "black", fontWeight: "bold" };
      break;
  }

  useEffect(() => {
    const searchCustomerTextSuccess = (
      <p style={{ color: "green", fontWeight: "bold" }}>
        Customer record found
      </p>
    );
    const searchCustomerTextNotFound = (
      <p style={{ color: "red", fontWeight: "bold" }}>No customer record</p>
    );
    const mobileNumberValidationText = (
      <p style={{ color: "red", fontWeight: "bold" }}>
        Mobile Number should be 12 digits and should start with '63'
      </p>
    );
    let identifier;
    if (mobileNumber) {
      identifier = setTimeout(() => {
        if (mobileNumber.length !== 12 || !mobileNumber.startsWith("63")) {
          setCustomerFound(false);
          setCustomerName("");
          setCustomerPoints("");
          setCustomerVisitCount("");
          setCustomerCancelCount("");
          setCustomerStatus("");
          setCustomerType("");
          setSearchCustomerText(mobileNumberValidationText);
          return;
        }
        fetch(
          `${
            process.env.REACT_APP_BACKEND_API
          }/v1/customers/${mobileNumber}?storeCode=${authCtx.getStoreCode()}`,
          {
            headers: {
              "Content-Type": "application/json",
              STORE_CODE: authCtx.getStoreCode(),
              Authorization: `Bearer ${authCtx.getToken()}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              response.json().then((data) => {
                setCustomerName(data.firstName.toUpperCase());
                setCustomerPoints(data.pointsEarned);
                setCustomerVisitCount(data.visitCount);
                setCustomerCancelCount(data.cancelCount);
                setCustomerStatus(data.status);
                setCustomerType(data.type);
                setCustomerFound(true);
                setSearchCustomerText(searchCustomerTextSuccess);
              });
            } else {
              setCustomerFound(false);
              setCustomerName("");
              setCustomerPoints("");
              setCustomerVisitCount("");
              setCustomerCancelCount("");
              setCustomerStatus("");
              setCustomerType("");
              setSearchCustomerText(searchCustomerTextNotFound);
            }
          })
          .catch((error) => console.error(error));
      }, 1000);
    } else {
      setCustomerFound(false);
      setCustomerName("");
      setCustomerPoints("");
      setCustomerVisitCount("");
      setCustomerCancelCount("");
      setCustomerStatus("");
      setCustomerType("");
      setSearchCustomerText("");
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [mobileNumber, authCtx]);

  useEffect(() => {
    if (props.availableServices.length > 0) {
      setSelectedService(props.availableServices[0]);
      props.fetchAvailableResources({
        startTime: selectedStartTime,
        startDate: startDate,
        appointmentId: "",
        serviceId: props.availableServices[0].id,
      });
    }
  }, [props.availableServices]);

  useEffect(() => {
    if (isCheckedInStatus && selectedService) {
      const selectedServicePrice = selectedService.price;
      let toPay = selectedServicePrice;
      const promo = Utils.getObject(promoId, props.availablePromos);
      const points = pointsToRedeem ? pointsToRedeem : 0;
      const promoLess =
        promoId &&
        selectedService &&
        promo.serviceLessPriceMap &&
        promo.serviceLessPriceMap[selectedService.id] !== ""
          ? promo.serviceLessPriceMap[selectedService.id]
          : 0;
      toPay = selectedServicePrice - points - promoLess;
      setPaymentAmount(toPay);
      setBasePaymentAmount(toPay);
    }
  }, [appointmentStatus, selectedService, pointsToRedeem, promoId]);

  return (
    <Form onSubmit={submitBookingHandler} size="tiny" key="tiny">
      <fieldset className="marginBottom10 marginLeft10 fieldset">
        <legend>Customer</legend>
        <Grid columns={2}>
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="mobileNumber"
              className="borderNone paddingRight0"
            >
              Mobile No.
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              id="mobileNumber"
              name="mobileNumber"
              icon="search"
              value={mobileNumber}
              required
              onChange={(event) => setMobileNumber(event.target.value)}
              type="number"
              placeholder="639xxxxxxxxx"
              min="0"
            />
            {searchCustomerText}
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Grid columns={2}>
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="customerName"
              className="borderNone paddingRight0"
            >
              Name
            </Label>
          </Grid.Column>
          <Grid.Column width={8}>
            <Input
              id="customerName"
              name="customerName"
              value={customerName}
              required
              type="text"
              disabled={customerFound}
              onChange={(event) => setCustomerName(event.target.value)}
              placeholder="Customer Name"
            />
          </Grid.Column>
          {customerFound && (
            <Grid.Column
              width={4}
              style={{ textAlign: "center", paddingLeft: "0px" }}
            >
              <span style={statusStyle}>{customerStatus}</span>
              <Divider fitted />
              <span style={customerTypeStyle}>{customerType}</span>
            </Grid.Column>
          )}
        </Grid>
        <Divider hidden fitted />

        {customerFound && (
          <CustomerStats
            customerStatus={customerStatus}
            customerVisitCount={customerVisitCount}
            customerCancelCount={customerCancelCount}
            customerPoints={customerPoints}
          ></CustomerStats>
        )}
      </fieldset>

      <fieldset className="marginBottom10 marginLeft10 fieldset">
        <legend>Service</legend>

        <Grid columns={2}>
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="startDate"
              className="borderNone paddingRight0"
            >
              Start Date
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              id="startDate"
              name="startDate"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              max={!props.isMassageAppointment ? Utils.getDateToday() : ""}
            />
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="startTime"
              className="borderNone paddingRight0"
            >
              Start Time
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <select
              id="startTime"
              name="startTime"
              onChange={handleStartTimeOnChange}
              value={selectedStartTime}
            >
              {props.appointmentStartTimes.map((startTime) => (
                <option key={startTime} value={startTime}>
                  {Utils.convertToAMPM(startTime)}
                </option>
              ))}
            </select>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Fragment>
          <Grid columns={2}>
            <Grid.Column width={4}>
              <Label basic className="borderNone" htmlFor="serviceId">
                Service
              </Label>
            </Grid.Column>
            <Grid.Column width={12}>
              <select
                id="serviceId"
                name="serviceId"
                onChange={handleChangeService}
                required
              >
                {props.availableServices.map((service) => (
                  <option key={service.id} value={service.id}>
                    {`${service.name.toUpperCase()} (PHP${service.price})`}
                  </option>
                ))}
              </select>
            </Grid.Column>
          </Grid>
          <Divider hidden fitted />
        </Fragment>

        {props.isMassageAppointment && (
          <Fragment>
            <Grid columns={2}>
              <Grid.Column width={4}>
                <Label htmlFor="staffId" basic className="borderNone">
                  Therapist
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <select
                  id="staffId"
                  name="staffId"
                  value={selectedTherapist}
                  onChange={(e) => setSelectedTherapist(e.target.value)}
                  required
                >
                  <option value="">-----</option>
                  <optgroup label="OPENING">
                    {props.availableTherapists &&
                      props.availableTherapists
                        .filter(
                          (staff) => staff.staffScheduleType === "OPENING"
                        )
                        .map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.name.toUpperCase()}
                          </option>
                        ))}
                  </optgroup>
                  <optgroup label="MID">
                    {props.availableTherapists &&
                      props.availableTherapists
                        .filter((staff) => staff.staffScheduleType === "MID")
                        .map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.name.toUpperCase()}
                          </option>
                        ))}
                  </optgroup>
                  <optgroup label="CLOSING">
                    {props.availableTherapists &&
                      props.availableTherapists
                        .filter(
                          (staff) => staff.staffScheduleType === "CLOSING"
                        )
                        .map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.name.toUpperCase()}
                          </option>
                        ))}
                  </optgroup>
                </select>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />

            <Grid columns={2}>
              <Grid.Column width={4}>
                <Label
                  htmlFor="therapistRequested"
                  basic
                  className="borderNone paddingRight0"
                >
                  Therapist Requested
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Checkbox
                  toggle
                  id="therapistRequested"
                  name="therapistRequested"
                  className="marginTop10"
                  checked={therapistRequested}
                  onChange={(e) => {
                    if (selectedService && selectedService.hasStaff) {
                      setTherapistRequested(e.target.checked);
                    } else {
                      setTherapistRequested(false);
                    }
                  }}
                ></Checkbox>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}

        {props.isMassageAppointment && (
          <Fragment>
            <Grid columns={2}>
              <Grid.Column width={4}>
                <Label htmlFor="roomId" basic className="borderNone">
                  Room No.
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <select
                  id="roomId"
                  name="roomId"
                  value={selectedRoom}
                  onChange={(e) => setSelectedRoom(e.target.value)}
                  required
                >
                  <option value="">{Utils.BLANK}</option>
                  {props.availableRooms &&
                    props.availableRooms.map((room) => (
                      <option key={room.id} value={room.id}>
                        {room.name}
                      </option>
                    ))}
                </select>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}

        <Grid columns={2}>
          <Grid.Column width={4}>
            <Label
              htmlFor="lockerId"
              basic
              className="borderNone paddingRight0"
            >
              Locker No.
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <select
              id="lockerId"
              name="lockerId"
              value={selectedLocker}
              onChange={(e) => setSelectedLocker(e.target.value)}
            >
              <option value="">{Utils.BLANK}</option>
              {props.availableLockers &&
                props.availableLockers.map((locker) => (
                  <option key={locker.id} value={locker.id}>
                    {locker.name}
                  </option>
                ))}
            </select>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />
      </fieldset>

      <fieldset className="marginBottom10 marginLeft10 fieldset">
        <legend>Payment</legend>

        {isCheckedInStatus && (
          <Fragment>
            <Grid columns={2} verticalAlign="middle">
              <Grid.Column width={4}>
                <Label
                  basic
                  htmlFor="promoId"
                  className="borderNone paddingRight0"
                >
                  Promo
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <select
                  id="promoId"
                  name="promoId"
                  value={promoId}
                  onChange={(e) => {
                    setPromoId(e.target.value);
                    setHasCheckInPercentCharge(false);
                    setCheckInPercentChargeAmount(0);
                  }}
                >
                  <option value="">{Utils.BLANK}</option>
                  {props.availablePromos &&
                    props.availablePromos.map((promo) => (
                      <option key={promo.id} value={promo.id}>
                        {promo.name.toUpperCase()}
                      </option>
                    ))}
                </select>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}

        {isCheckedInStatus && customerPoints > 100 && (
          <Fragment>
            <Grid columns={2}>
              <Grid.Column width={4}>
                <Label
                  basic
                  htmlFor="pointsToRedeem"
                  className="borderNone paddingRight0"
                >
                  Redeem Loyalty Points
                </Label>
              </Grid.Column>
              <Grid.Column width={12} className="marginTop10">
                <select
                  id="pointsToRedeem"
                  name="pointsToRedeem"
                  value={pointsToRedeem}
                  onChange={(e) => {
                    setPointsToRedeem(e.target.value);
                    setHasCheckInPercentCharge(false);
                    setCheckInPercentChargeAmount(0);
                  }}
                >
                  <option value="">{Utils.BLANK}</option>
                  {customerPoints !== "" &&
                    Utils.generatePointsToRedeemDropdown(
                      props.store.loyaltyMinimumPointsToRedeem,
                      props.store.loyaltyPointsToRedeemDivisibleBy,
                      parseInt(customerPoints)
                    )}
                </select>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="paymentAmount"
              className="borderNone paddingRight0"
            >
              Amount
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Input
              id="paymentAmount"
              name="paymentAmount"
              type="number"
              min="0"
              required
              step="any"
              placeholder="PHP"
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              className={paymentAmount < 0 ? "negative-input" : ""}
            ></Input>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={4}>
            <Label
              basic
              htmlFor="modeOfPayment"
              className="borderNone paddingRight0"
            >
              Mode of Payment (MOP)
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <select id="modeOfPayment" name="modeOfPayment">
              {props.modeOfPayments &&
                props.modeOfPayments.map((mode) => (
                  <option key={mode} value={mode}>
                    {mode.replace("_", " ")}
                  </option>
                ))}
            </select>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        {isCheckedInStatus && (
          <Fragment>
            <Grid columns={2} verticalAlign="middle">
              <Grid.Column width={4}>
                <Label
                  basic
                  htmlFor="percentChargeField"
                  className="borderNone paddingRight0"
                >
                  3% Charge
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Checkbox
                  toggle
                  id="percentChargeField"
                  name="hasCheckInPercentCharge"
                  className="marginTop10"
                  checked={hasCheckInPercentCharge}
                  onChange={(e) => {
                    setHasCheckInPercentCharge(e.target.checked);
                    if (e.target.checked) {
                      setPaymentAmount(
                        basePaymentAmount + basePaymentAmount * 0.03
                      );
                      setCheckInPercentChargeAmount(basePaymentAmount * 0.03);
                    } else {
                      setPaymentAmount(basePaymentAmount);
                      setCheckInPercentChargeAmount(0);
                    }
                  }}
                ></Checkbox>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}
      </fieldset>

      <fieldset className="marginBottom10 marginLeft10 fieldset">
        <legend>Other Details</legend>

        {props.isMassageAppointment && (
          <Fragment>
            <Grid>
              <Grid.Column width={7}>
                <Popup
                  trigger={
                    <Label
                      basic
                      htmlFor="createdDate"
                      className="borderNone paddingRight0"
                    >
                      <Icon name="question circle" size="small" />
                      Created Date
                    </Label>
                  }
                  content="Actual date when appointment is created. To track deposit payments on reservations done on previous day."
                  on="hover"
                  position="top right"
                />
              </Grid.Column>
              <Grid.Column width={9}>
                <Input
                  id="createdDate"
                  name="createdDate"
                  type="date"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Fragment>
        )}

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={7}>
            <Label
              basic
              htmlFor="bookingChannel"
              className="borderNone paddingRight0"
            >
              Booking Channel
            </Label>
          </Grid.Column>
          <Grid.Column width={9}>
            <select id="bookingChannel" name="type">
              {props.appointmentTypes &&
                props.appointmentTypes.map((type) => (
                  <option key={type} value={type}>
                    {type.replace("_", " ")}
                  </option>
                ))}
            </select>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Grid>
          <Grid.Column width={7}>
            <Popup
              trigger={
                <Label
                  basic
                  htmlFor="status"
                  className="borderNone paddingRight0"
                >
                  <Icon name="question circle" size="small" />
                  Status
                </Label>
              }
              content="If RESERVED, an SMS will be sent to customer."
              on="hover"
              position="top right"
            />
          </Grid.Column>
          <Grid.Column width={9}>
            <select
              id="status"
              name="status"
              value={appointmentStatus}
              onChange={(e) => {
                const status = e.target.value;
                setAppointmentStatus(status);
              }}
            >
              {props.appointmentStatuses &&
                props.appointmentStatuses.map((status) => {
                  if (status !== "COMPLETED" && status !== "CANCELLED") {
                    if (!props.isMassageAppointment) {
                      if (status === "CHECKED_IN") {
                        return (
                          <option key={status} value={status}>
                            {status.replace("_", "-")}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    }
                    return (
                      <option key={status} value={status}>
                        {status.replace("_", "-")}
                      </option>
                    );
                  }
                  return null;
                })}
            </select>
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={4}>
            <Label basic htmlFor="status" className="borderNone paddingRight0">
              Note
            </Label>
          </Grid.Column>
          <Grid.Column width={12}>
            <TextArea id="note" name="note" type="text" placeholder="Note" />
          </Grid.Column>
        </Grid>
        <Divider hidden fitted />
      </fieldset>

      <Grid className="marginTop10 marginBottom10 marginLeft10">
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={12}>
          <Button
            type="submit"
            primary
            disabled={customerStatus === "INACTIVE"}
          >
            BOOK APPOINTMENT
          </Button>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
      </Grid>
    </Form>
  );
};

export default AppointmentForm;
