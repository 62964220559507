import React, { Fragment, useContext, useState } from "react";
import { Grid, Button, Form, Icon } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import ErrorMessage from "../common/ErrorMessage";

const CustomerReportForm = () => {
  const authCtx = useContext(AuthContext);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitBookingHandler = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setDisableButton(true);

    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND_API
      }/v1/reports/customers/download?storeCode=${authCtx.getStoreCode()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          STORE_CODE: authCtx.getStoreCode(),
          Authorization: `Bearer ${authCtx.getToken()}`,
        },
      }
    );

    if (response.ok) {
      const now = new Date();
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const datePart = now.toLocaleString(undefined, options).replace(/,/g, ""); // Remove commas
      const filename = `Customers_${datePart}.xlsx`;

      setDisableButton(false);

      return response.blob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      handleFormError("Report generation failed");
    }

    setDisableButton(false);
  };

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
  };

  return (
    <Fragment>
      <Form onSubmit={submitBookingHandler}>
        <Grid>
          {errorMessage && (
            <Grid.Row>
              <Grid.Column width="5">
                <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width="16">
              <Button disabled={disableButton} primary>
                <Icon name="download" />
                DOWNLOAD
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Fragment>
  );
};

export default CustomerReportForm;
