import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const YearDropdown = (props) => {
  const [selectedYear, setSelectedYear] = useState(props.year);

  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  const startYear = 2023;

  for (let year = startYear; year <= currentYear; year++) {
    yearOptions.push({
      key: year,
      text: year.toString(),
      value: year,
    });
  }

  const handleChange = (event, data) => {
    setSelectedYear(data.value);
    props.handleYearChange(data.value);
  };

  return (
    <Dropdown
      placeholder="Select Year"
      fluid={false}
      selection
      options={yearOptions}
      value={selectedYear}
      onChange={handleChange}
    />
  );
};

export default YearDropdown;
