const API_BASE_URL = process.env.REACT_APP_BACKEND_API;

const DEFAULT_ERROR_MESSAGE = "Something went wrong";
const MISSING_PARAMETERS = "Missing parameters.";

export async function authenticateApi(username, password) {
  if (!username || !password) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/auth/authenticate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  if (response.status === 403) {
    throw new Error("Access forbidden. Please check your credentials.");
  }
  if (!response.ok) {
    const error = await response.json();
    console.log(error);
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }
  return response.json();
}

export async function createAppointmentApi(payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/v1/appointments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to create appointment.");
  } else {
    return response.json();
  }
}

export async function updateAppointmentApi(id, payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/v1/appointments/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update appointment.");
  }
}

export async function checkInAppointmentApi(id, payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(
    `${API_BASE_URL}/v1/appointments/check-in/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        STORE_CODE: storeCode,
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    }
  );

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to check-in appointment.");
  }
}

export async function completeAppointmentApi(id, payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(
    `${API_BASE_URL}/v1/appointments/complete/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        STORE_CODE: storeCode,
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    }
  );

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to complete appointment.");
  }
}

export async function cancelAppointmentApi(id, payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/v1/appointments/cancel/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to cancel appointment.");
  }
}

export async function getAppointmentsByDateApi(
  start,
  basedOnCurrentTime,
  storeCode,
  token
) {
  if (!start || !storeCode || !token) {
    throw new Error(MISSING_PARAMETERS);
  }

  const url = `${API_BASE_URL}/v1/appointments?start=${start}&storeCode=${storeCode}&basedOnCurrentTime=${basedOnCurrentTime}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to get appointments by date.");
  } else {
    return response.json();
  }
}

export async function getLockersApi(isAvailable, storeCode, token) {
  const url = `${API_BASE_URL}/v1/lockers?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to get lockers.");
  } else {
    return await response.json();
  }
}

export async function addLockerApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/lockers`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add locker.");
  } else {
    return response.json();
  }
}

export async function updateLockerApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/lockers/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update locker.");
  } else {
    return response.json();
  }
}

export async function getRoomsApi(isAvailable, storeCode, token) {
  const url = `${API_BASE_URL}/v1/rooms?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to get rooms.");
  } else {
    return await response.json();
  }
}

export async function addRoomApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/rooms`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add room.");
  } else {
    return response.json();
  }
}

export async function updateRoomApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/rooms/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update room.");
  } else {
    return response.json();
  }
}

export async function getPromosApi(isAvailable, storeCode, token) {
  const url = `${API_BASE_URL}/v1/promos?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get promos.");
  } else {
    return await response.json();
  }
}

export async function addPromoApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/promos`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add promo.");
  } else {
    return response.json();
  }
}

export async function updatePromoApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/promos/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update locker.");
  } else {
    return response.json();
  }
}

export async function getProductsApi(isAvailable, storeCode, token) {
  const url = `${API_BASE_URL}/v1/products?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Retrieving products has failed.");
  } else {
    return await response.json();
  }
}

export async function addProductApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/products`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Adding a new product has failed.");
  } else {
    return response.json();
  }
}

export async function updateProductApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/products/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Updating product has failed.");
  } else {
    return response.json();
  }
}

export async function downloadReportApi(start, end, storeCode, token) {
  const url = `${API_BASE_URL}/v1/reports/download?start=${start}&end=${end}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to download report.");
  }
}

export async function getStaffsApi(isAvailable, serviceDate, storeCode, token) {
  let url = `${API_BASE_URL}/v1/staffs?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  if (serviceDate) {
    url += `&serviceDate=${serviceDate}`;
  }
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get staffs.");
  } else {
    return await response.json();
  }
}

export async function addStaffApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/staffs`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add staff.");
  } else {
    return response.json();
  }
}

export async function updateStaffApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/staffs/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update staff.");
  } else {
    return response.json();
  }
}

export async function getStaffByIdApi(id, storeCode, token) {
  const url = `${API_BASE_URL}/v1/staffs/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get staff.");
  } else {
    return await response.json();
  }
}

export async function getServicesApi(isAvailable, storeCode, token) {
  const url = `${API_BASE_URL}/v1/services-offered?availableOnly=${isAvailable}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get services.");
  } else {
    return await response.json();
  }
}

export async function addServiceApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/services-offered`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add service.");
  } else {
    return response.json();
  }
}

export async function updateServiceApi(id, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/services-offered/${id}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "PATCH",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update service.");
  } else {
    return response.json();
  }
}

export async function getAppointmentConfigApi(storeCode, token) {
  const url = `${API_BASE_URL}/v1/appointments/config?storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get staffs.");
  } else {
    return await response.json();
  }
}

export async function getAppointmentBasedOnLockerInUseNow(
  id,
  storeCode,
  token
) {
  const url = `${API_BASE_URL}/v1/appointments/locker/${id}?storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get appointment based on locker in use.");
  } else {
    return await response.json();
  }
}

export async function getCustomerByIdentifierApi(
  id,
  includeAppointments,
  includeUpdatePointsLogs,
  storeCode,
  token
) {
  const url = `${API_BASE_URL}/v1/customers/${id}?storeCode=${storeCode}&includeAppointments=${includeAppointments}&includeUpdatePointsLogs=${includeUpdatePointsLogs}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get staffs.");
  } else {
    return await response.json();
  }
}

export async function updateCustomer(id, payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/v1/customers/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update appointment.");
  }
}

export async function updateCustomerPoints(payload, storeCode, token) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(`${API_BASE_URL}/v1/customers/updatePoints`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to update appointment.");
  }
}

export async function getAvailableResourcesApi(
  startTime,
  startDate,
  appointmentId,
  serviceId,
  storeCode,
  token
) {
  const url = `${API_BASE_URL}/v1/appointments/available-resources?storeCode=${storeCode}&startTime=${startTime}&startDate=${startDate}&appointmentId=${appointmentId}&serviceId=${serviceId}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get available resources.");
  } else {
    return await response.json();
  }
}

export async function getUnavailableResourcesApi(
  startTime,
  startDate,
  storeCode,
  token
) {
  const url = `${API_BASE_URL}/v1/appointments/unavailable-resources?storeCode=${storeCode}&startTime=${startTime}&startDate=${startDate}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get unavailable resources.");
  } else {
    return await response.json();
  }
}

export async function getAdminsApi(roles, storeCode, token) {
  const url = `${API_BASE_URL}/v1/users?roles=${roles}&storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get services.");
  } else {
    return await response.json();
  }
}

export async function addAdminApi(payload, storeCode, token) {
  const url = `${API_BASE_URL}/auth/register`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to add admin.");
  } else {
    return response.json();
  }
}

export async function updateAdminApi(username, payload, storeCode, token) {
  const url = `${API_BASE_URL}/v1/users/${username}?storeCode=${storeCode}`;
  const response = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      STORE_CODE: storeCode,
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get services.");
  } else {
    return await response.json();
  }
}

export async function getStoreApi(storeCode, token) {
  const url = `${API_BASE_URL}/v1/stores?storeCode=${storeCode}`;
  const headers = {
    "Content-Type": "application/json",
    STORE_CODE: storeCode,
    Authorization: `Bearer ${token}`,
  };
  const response = await fetch(url, { method: "GET", headers });
  if (!response.ok) {
    const error = await response.json();
    throwError(error, "Failed to get services.");
  } else {
    return await response.json();
  }
}

export async function updateStoreLoyaltyPointsConfig(
  payload,
  storeCode,
  token
) {
  if (!payload) {
    throw new Error(MISSING_PARAMETERS);
  }

  const response = await fetch(
    `${API_BASE_URL}/v1/stores/loyalty/${storeCode}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        STORE_CODE: storeCode,
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    }
  );

  if (!response.ok) {
    const error = await response.json();
    const errorMessage = extractErrorMessage(error);
    throw new Error(errorMessage || "Failed to store loyalty config.");
  }
}

function throwError(errorJson, defaultErrorMessage) {
  const errorMessage = extractErrorMessage(errorJson);
  throw new Error(errorMessage || defaultErrorMessage);
}

function extractErrorMessage(error) {
  const errorMessages = [];

  if (error && error.error && error.error.message) {
    errorMessages.push(error.error.message);
  }

  if (
    error &&
    error.error &&
    error.error.details &&
    error.error.details.errors
  ) {
    errorMessages.push(...error.error.details.errors);
  }

  return errorMessages.filter(Boolean).join(" - ");
}
