import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { Checkbox, Grid, Label } from "semantic-ui-react";
import AppointmentCalendar from "../components/appointment/AppointmentCalendar";
import AppointmentForm from "../components/appointment/AppointmentForm";
import SearchActiveLocker from "../components/appointment/SearchActiveLocker";
import ErrorMessage from "../components/common/ErrorMessage";
import Legend from "../components/common/Legend";
import Utils from "../utils/Utils";
import AuthContext from "../store/auth-context";
import {
  getStoreApi,
  getStaffsApi,
  getServicesApi,
  getLockersApi,
  getPromosApi,
  getRoomsApi,
  getAppointmentsByDateApi,
} from "../api/api";

const Dashboard = (props) => {
  const authCtx = useContext(AuthContext);
  const [store, setStore] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [availablePromos, setAvailablePromos] = useState([]);

  const [allLockers, setAllLockers] = useState([]);
  const [allTherapists, setAllTherapists] = useState([]);
  const [therapistsOnDuty, setTherapistsOnDuty] = useState([]);
  const [allRooms, setAllRooms] = useState([]);

  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [currentView, setCurrentView] = useState(Utils.getDateToday());
  const [showAllTherapists, setShowAllTherapists] = useState(false);

  const handleOnCloseModal = (needsReload) => {
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchTherapists = useCallback(
    async (serviceDate) => {
      try {
        const response = await getStaffsApi(
          true,
          serviceDate,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );

        const loadedTherapists = [];
        const loadedTherapistsOnDuty = [];
        for (const key in response) {
          let staff = Utils.toStaff(response[key]);
          if (!staff.dayOffNow) {
            loadedTherapistsOnDuty.push(staff);
          }
          loadedTherapists.push(staff);
        }
        setAllTherapists(loadedTherapists);
        setTherapistsOnDuty(loadedTherapistsOnDuty);
      } catch (error) {
        console.log("error: " + error);
      }
    },
    [authCtx]
  );

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push({
          id: response[key].id,
          name: response[key].name,
          price: response[key].price,
          duration: response[key].duration,
          breakDuration: response[key].breakDuration,
          type: response[key].type,
          available: response[key].available,
          hasStaff: response[key].hasStaff,
          hasRoom: response[key].hasRoom,
        });
      }
      setServices(loadedServices);
      setAvailableServices(
        loadedServices.filter((service) => service.type === "MASSAGE")
      );
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchLockers = useCallback(async () => {
    try {
      const response = await getLockersApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedLockers = [];
      for (const key in response) {
        loadedLockers.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
        });
      }
      setAllLockers(loadedLockers);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await getRoomsApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      const loadedRooms = [];

      for (const key in response) {
        loadedRooms.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
          serviceIds: response[key].serviceIds,
          type: response[key].type,
        });
      }
      setAllRooms(loadedRooms);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchPromos = useCallback(async () => {
    try {
      const response = await getPromosApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      const loadedPromos = [];

      for (const key in response) {
        loadedPromos.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
          serviceLessPriceMap: response[key].serviceLessPriceMap,
          createdDateTime: response[key].createdDateTime,
          updatedDateTime: response[key].updatedDateTime,
        });
      }
      setAvailablePromos(loadedPromos);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchStore = useCallback(async () => {
    try {
      const response = await getStoreApi(
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      let storeData = Utils.toStore(response);
      setStore(storeData);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchAppointmentsByDate = useCallback(
    async (date) => {
      try {
        const response = await getAppointmentsByDateApi(
          date,
          false,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );
        const loadedAppointments = [];
        for (const key in response) {
          let appointmentResponse = response[key];
          if (appointmentResponse.serviceOfferedType === "MASSAGE") {
            loadedAppointments.push(Utils.toAppointment(appointmentResponse));
          }
        }
        setAppointments(loadedAppointments);
        setCurrentView(date);
      } catch (error) {
        console.log("error: " + error);
      }
    },
    [authCtx]
  );

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setFormErrorMessage(errorMessage);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchStore();
      fetchLockers();
      fetchTherapists(currentView);
      fetchRooms();
      fetchServices();
      fetchPromos();
      fetchAppointmentsByDate(Utils.getDateToday());
    }
  }, [
    fetchStore,
    fetchTherapists,
    fetchServices,
    fetchLockers,
    fetchRooms,
    fetchPromos,
    fetchAppointmentsByDate,
    authCtx,
  ]);

  return (
    <Fragment>
      <Grid>
        <Grid.Row className="paddingBottom0">
          <Grid.Column width={4} textAlign="center">
            <h3>Create Appointment Form</h3>
          </Grid.Column>
          <Grid.Column width={3} className="marginTop10">
            <div>{Utils.convertToDescriptiveDate(currentView)}</div>
            <div>
              <Label
                htmlFor="therapistRequested"
                basic
                className="borderNone paddingRight0 paddingLeft0"
              >
                Show All Active Therapists:
              </Label>
              <span className="marginLeft10">
                <Checkbox
                  toggle
                  id="showAllTherapists"
                  checked={showAllTherapists}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowAllTherapists(true);
                      setTherapistsOnDuty(allTherapists);
                    } else {
                      setShowAllTherapists(false);
                      fetchTherapists(currentView);
                    }
                  }}
                ></Checkbox>
              </span>
            </div>
          </Grid.Column>
          <Grid.Column width={6}>
            <Legend></Legend>
          </Grid.Column>
          <Grid.Column width={3}>
            <SearchActiveLocker
              store={store}
              fetchAvailableResources={props.fetchAvailableResources}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              availableTherapists={props.availableTherapists}
              services={services}
              availableServices={availableServices}
              availablePromos={availablePromos}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              additionalTowelPayment={props.additionalTowelPayment}
              isMassageAppointment={true}
              handleOnCloseModal={handleOnCloseModal}
              productTypes={props.productTypes}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="paddingTop0">
          <Grid.Column width={4}>
            {formErrorMessage && (
              <div className="marginLeft10">
                <ErrorMessage errorMessage={formErrorMessage}></ErrorMessage>
              </div>
            )}
            <AppointmentForm
              store={store}
              fetchAvailableResources={props.fetchAvailableResources}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              availableTherapists={props.availableTherapists}
              services={services}
              availableServices={availableServices}
              availablePromos={availablePromos}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              additionalTowelPayment={props.additionalTowelPayment}
              handleFormError={handleFormError}
              fetchAppointmentsByDate={fetchAppointmentsByDate}
              isMassageAppointment={true}
              fetchTherapists={fetchTherapists}
              setShowAllTherapists={setShowAllTherapists}
            ></AppointmentForm>
          </Grid.Column>
          <Grid.Column
            width={12}
            className="marginLeft0 paddingLeft0 marginTop10"
          >
            <AppointmentCalendar
              store={store}
              fetchAvailableResources={props.fetchAvailableResources}
              fetchUnavailableResources={props.fetchUnavailableResources}
              events={appointments}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              therapistsOnDuty={therapistsOnDuty}
              availableTherapists={props.availableTherapists}
              services={services}
              availableServices={availableServices}
              availablePromos={availablePromos}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              additionalTowelPayment={props.additionalTowelPayment}
              currentView={currentView}
              isMassageAppointment={true}
              staffBreaks={props.staffBreaks}
              onBreak1={props.onBreak1}
              onBreak2={props.onBreak2}
              onDayOff={props.onDayOff}
              productTypes={props.productTypes}
              handleOnCloseModal={handleOnCloseModal}
            ></AppointmentCalendar>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default Dashboard;
