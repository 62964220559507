import { Icon } from "semantic-ui-react";

const UpdateReminder = (props) => {
  return (
    <p className="italic">
      <Icon name="info" color="yellow" size="big"></Icon>
      Updates are only applied to newly created appointments.
    </p>
  );
};

export default UpdateReminder;
