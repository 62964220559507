import { Message, Icon } from "semantic-ui-react";

const ErrorMessage = (props) => {
  return (
    <Message negative>
      <Message.Header>
        <Icon name="exclamation" color="red" size="large"></Icon>Something went
        wrong
      </Message.Header>
      <Message.Content>
        <div className="marginTop20 marginLeft30">{props.errorMessage}</div>
      </Message.Content>
    </Message>
  );
};

export default ErrorMessage;
