import { Fragment, useState, useContext } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  Label,
  Input,
  Grid,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import SuccessMessage from "../common/SuccessMessage";
import AuditInfo from "../common/AuditInfo";
import UpdateReminder from "../common/UpdateReminder";
import { updateServiceApi, addServiceApi } from "../../api/api";

const ServicesModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [name, setName] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedService.name : ""
  );
  const [isAvailable, setIsAvailable] = useState(true);
  const [price, setPrice] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedService.price : ""
  );
  const [duration, setDuration] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedService.duration : ""
  );
  const [breakDuration, setBreakDuration] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedService.breakDuration
      : ""
  );
  const [type, setType] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedService.type : ""
  );

  const [hasUpdates, setHasUpdates] = useState(false);
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");

  const roomOptions = props.availableRooms.map((room) => ({
    key: room.id,
    text: room.name,
    value: room.id,
    selected: props.selectedService
      ? room.serviceIds && room.serviceIds.includes(props.selectedService.id)
      : false,
  }));

  const selectedRooms = roomOptions
    .filter((option) => option.selected)
    .map((option) => option.value);

  const [rooms, setRooms] = useState(selectedRooms);

  const handleRoomSelect = (event, data) => {
    setRooms(data.value);
  };

  const [promoDiscounts, setPromoDiscounts] = useState(() => {
    const newPromoDiscounts = {};

    props.availablePromos.forEach((promo) => {
      if (
        props.selectedService &&
        promo.serviceLessPriceMap &&
        promo.serviceLessPriceMap[props.selectedService.id]
      ) {
        newPromoDiscounts[promo.id] =
          promo.serviceLessPriceMap[props.selectedService.id];
      }
    });

    return newPromoDiscounts;
  });

  const handlePromoDiscountChange = (promoId, value) => {
    if (value) {
      setPromoDiscounts((prevState) => ({
        ...prevState,
        [promoId]: value,
      }));
    } else {
      setPromoDiscounts((prevState) => {
        const newState = { ...prevState };
        delete newState[promoId];
        return newState;
      });
    }
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalServiceForm");
    if (form) {
      const formData = new FormData(form);

      const updateData = {};
      for (let [name, value] of formData.entries()) {
        updateData[name] = value ? value.trim() : null;
      }

      if (!updateData.name) {
        handleFormError("Name is required and should be unique");
        return;
      }

      if (!updateData.price) {
        handleFormError("Price is required");
        return;
      }

      if (!updateData.type) {
        handleFormError("Type is required");
        return;
      }

      if (
        updateData.type === "MASSAGE" &&
        (!updateData.duration || !updateData.breakDuration)
      ) {
        handleFormError(
          "Duration and Break Duration is required for MASSAGE type"
        );
        return;
      }

      updateData["available"] = "true"; //isAvailable ? "true" : "false";

      updateData.storeCode = authCtx.getStoreCode();
      updateData.rooms = rooms;
      updateData.promoLessMap = promoDiscounts;

      console.log("update services: ", JSON.stringify(updateData));

      updateServiceApi(
        props.selectedService.id,
        JSON.stringify(updateData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Service details updated");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  const handleAddButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalServiceForm");
    if (form) {
      const formData = new FormData(form);

      const data = {};
      for (let [name, value] of formData.entries()) {
        data[name] = value ? value.trim() : null;
      }

      if (!data.name) {
        handleFormError("Name is required and should be unique");
        return;
      }

      if (!data.price) {
        handleFormError("Price is required");
        return;
      }

      if (!data.type) {
        handleFormError("Type is required");
        return;
      }

      if (data.type === "MASSAGE" && (!data.duration || !data.breakDuration)) {
        handleFormError(
          "Duration and Break Duration is required for MASSAGE type"
        );
        return;
      }

      data["available"] = "true"; //isAvailable ? "true" : "false";

      data.storeCode = authCtx.getStoreCode();
      data.rooms = rooms;
      data.promoLessMap = promoDiscounts;

      console.log("add service: ", JSON.stringify(data));

      addServiceApi(
        JSON.stringify(data),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Service added");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  return (
    <Fragment>
      <Modal
        closeIcon
        size="fullscreen"
        onClose={handleOnCloseModal}
        open={props.isModalOpen}
      >
        <Modal.Header>
          {props.eventName === Utils.EVENT_UPDATE &&
            `Update Service Details: ${props.selectedService.name}`}
          {props.eventName === Utils.EVENT_ADD && `Add Service`}
        </Modal.Header>
        <Modal.Content>
          {modalFormErrorMessage && (
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          )}
          {modalFormSuccessMessage && (
            <SuccessMessage
              successMessage={modalFormSuccessMessage}
            ></SuccessMessage>
          )}
          <Form size="small" key="small" id="modalServiceForm">
            <Grid columns={3}>
              <Grid.Row>
                <Grid.Column width={5}>
                  <fieldset className="fieldset" style={{ height: "365px" }}>
                    <legend>Service Details</legend>
                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalServiceName"
                          className="borderNone paddingRight0"
                        >
                          Service Name
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Input
                          id="modalServiceName"
                          name="name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          style={{ width: "200px" }}
                          required
                        ></Input>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    {/* <Grid columns={2}>
                    <Grid.Column width={4} className="marginTop10">
                      <Label
                        basic
                        htmlFor="modalIsAvailable"
                        className="borderNone paddingRight0"
                      >
                        Available
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12} className="paddingTop20">
                      <Checkbox
                        toggle
                        id="modalIsAvailable"
                        name="available"
                        className="marginTop10"
                        checked={isAvailable}
                        value={isAvailable ? "true" : "false"}
                        onChange={(e) => {
                          setIsAvailable(!isAvailable);
                        }}
                        required
                      ></Checkbox>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted /> */}

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalServicePrice"
                          className="borderNone paddingRight0"
                        >
                          Price (PHP)
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Input
                          id="modalServicePrice"
                          name="price"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                          required
                        ></Input>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalServiceDuration"
                          className="borderNone paddingRight0"
                        >
                          Duration (minutes)
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Input
                          id="modalServiceDuration"
                          name="duration"
                          value={duration}
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                        ></Input>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalServiceBreakDuration"
                          className="borderNone paddingRight0"
                        >
                          Cleaning Time (minutes)
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Input
                          id="modalServiceBreakDuration"
                          name="breakDuration"
                          value={breakDuration}
                          onChange={(e) => {
                            setBreakDuration(e.target.value);
                          }}
                        ></Input>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalServiceType"
                          className="borderNone paddingRight0"
                        >
                          Type
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <select
                          id="modalServiceType"
                          name="type"
                          style={{ width: "170px" }}
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        >
                          {props.serviceOfferedTypes &&
                            props.serviceOfferedTypes.map(
                              (serviceOfferedType) => {
                                return (
                                  <option
                                    id={serviceOfferedType}
                                    key={serviceOfferedType}
                                    value={serviceOfferedType}
                                  >
                                    {serviceOfferedType}
                                  </option>
                                );
                              }
                            )}
                        </select>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </fieldset>
                </Grid.Column>

                <Grid.Column width={6} className="paddingRight0 paddingLeft0">
                  <fieldset className="fieldset">
                    <legend>Promos</legend>
                    <Grid columns={2}>
                      <Grid.Column width={4}>
                        <Label
                          basic
                          htmlFor="modalServiceCommissionMapping"
                          className="borderNone paddingRight0"
                        >
                          Discount
                          <div>
                            <sub>
                              Leave discount blank if promo is not available for
                              service.
                            </sub>
                          </div>
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        {props.availablePromos &&
                          props.availablePromos.map((promo) => (
                            <div key={promo.id}>
                              <Label basic className="borderNone marginTop10">
                                {promo.name}
                              </Label>
                              <Input
                                min="1"
                                type="number"
                                value={promoDiscounts[promo.id] || ""}
                                onChange={(e) =>
                                  handlePromoDiscountChange(
                                    promo.id,
                                    e.target.value
                                  )
                                }
                                style={{ width: "150px" }}
                              />
                            </div>
                          ))}
                      </Grid.Column>
                    </Grid>
                  </fieldset>
                </Grid.Column>

                <Grid.Column width={5}>
                  <fieldset className="fieldset" style={{ height: "365px" }}>
                    <legend>Rooms</legend>
                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalDayOff"
                          className="borderNone paddingRight0"
                        >
                          Rooms
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Dropdown
                          placeholder="Select valid rooms for service"
                          fluid
                          multiple
                          selection
                          options={roomOptions}
                          onChange={handleRoomSelect}
                          defaultValue={rooms}
                        />
                      </Grid.Column>
                    </Grid>
                  </fieldset>
                </Grid.Column>
              </Grid.Row>

              {props.eventName === Utils.EVENT_UPDATE && (
                <Grid.Row>
                  <Grid.Column width={8}>
                    <UpdateReminder></UpdateReminder>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <AuditInfo resource={props.selectedService}></AuditInfo>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {props.eventName === Utils.EVENT_UPDATE && (
            <Button color="blue" onClick={handleUpdateButton}>
              <Icon name="pencil" /> UPDATE
            </Button>
          )}
          {props.eventName === Utils.EVENT_ADD && (
            <Button color="blue" onClick={handleAddButton}>
              <Icon name="bed" /> SUBMIT
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default ServicesModal;
