import React, {
  Fragment,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import PromosTable from "../components/promos/PromosTable";
import Utils from "../utils/Utils";
import AuthContext from "../store/auth-context";
import { getPromosApi, getServicesApi } from "../api/api";
import PromoModal from "../components/promos/PromoModal";

const Promos = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allPromos, setAllPromos] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchPromos = useCallback(async () => {
    try {
      const response = await getPromosApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedPromos = [];
      for (const key in response) {
        loadedPromos.push(Utils.toPromo(response[key]));
      }
      setAllPromos(loadedPromos);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push(Utils.toService(response[key]));
      }
      setAllServices(loadedServices);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchPromos();
      fetchServices();
    }
  }, [fetchPromos, fetchServices, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <PromosTable promos={allPromos} services={allServices}></PromosTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <PromoModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD}
        />
      )}
    </Fragment>
  );
};

export default Promos;
