import React, { Fragment, useState, useContext } from "react";
import { Table } from "semantic-ui-react";
import StaffModal from "./StaffModal";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import ErrorMessage from "../common/ErrorMessage";
import { getStaffByIdApi } from "../../api/api";

const StaffsTable = (props) => {
  const authCtx = useContext(AuthContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState({});

  const [errorMessage, setErrorMessage] = useState("");

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
  };

  const onClick = (therapist) => {
    setErrorMessage("");
    getStaffByIdApi(therapist.id, authCtx.getStoreCode(), authCtx.getToken())
      .then((response) => {
        setIsModalOpen(true);
        setSelectedTherapist(Utils.toStaff(response));
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError("Something went wrong while getting therapist details");
      });
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <div style={{ maxHeight: "800px", overflow: "auto" }}>
        {errorMessage && (
          <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
        )}
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Service & Commission Mapping (PHP)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Schedule Type
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Break Hours
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Days Off</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!props.therapists || props.therapists.length === 0 ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={6}>
                  No therapists created
                </Table.Cell>
              </Table.Row>
            ) : (
              props.therapists.map((therapist) => {
                return (
                  <Table.Row key={therapist.id}>
                    <Table.Cell
                      className="pointer-on-hover"
                      onClick={() => onClick(therapist)}
                      textAlign="center"
                    >
                      {therapist.name}
                    </Table.Cell>
                    <Table.Cell
                      className={therapist.available ? "green" : "red"}
                      textAlign="center"
                    >
                      {therapist.available ? "Yes" : "No"}
                    </Table.Cell>
                    <Table.Cell>
                      <ul>
                        {therapist.serviceCommissionMap &&
                          Object.entries(therapist.serviceCommissionMap).map(
                            ([serviceId, commission]) => {
                              const service = props.services.find(
                                (service) => service.id === serviceId
                              );
                              const serviceName = service ? service.name : "";

                              return (
                                <li key={serviceId}>
                                  {`${serviceName} : ${commission}`}
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {therapist.staffScheduleType}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {Utils.getStaffBreakDisplay(
                        props.staffBreaks,
                        therapist.staffBreaks
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <ul>
                        {therapist.daysOff &&
                          therapist.daysOff.map((dayOff) => {
                            return <li key={dayOff}>{dayOff}</li>;
                          })}
                      </ul>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {isModalOpen && (
        <StaffModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedTherapist={selectedTherapist}
          staffScheduleTypes={props.staffScheduleTypes}
          services={props.services}
          staffBreaks={props.staffBreaks}
          eventName={Utils.EVENT_UPDATE}
        />
      )}
    </Fragment>
  );
};

export default StaffsTable;
