import { Icon, Label } from "semantic-ui-react";

const Legend = () => {
  return (
    <div className="marginTop10">
      <Label basic className="borderNone paddingRight0 marginRight5">
        Legend:
      </Label>
      <span className="marginRight5">
        <Icon name="circle" color="blue"></Icon>RESERVED
      </span>
      <span className="marginRight5">
        <Icon name="circle" color="yellow"></Icon>
        CHECKED-IN
      </span>
      <span className="marginRight5">
        <Icon name="circle" color="green"></Icon>COMPLETED
      </span>
      <span className="marginRight5">
        <Icon name="circle" color="red"></Icon>CANCELLED
      </span>
    </div>
  );
};

export default Legend;
