import React, { Fragment, useState, useContext } from "react";
import { Form, Input, Label, Grid, Button, Icon } from "semantic-ui-react";
import Utils from "../../utils/Utils";
import CustomerDetailsTable from "./CustomerDetailsTable";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import { updateCustomer } from "../../api/api";
import SuccessMessage from "../common/SuccessMessage";

const UpdateCustomerForm = (props) => {
  const authCtx = useContext(AuthContext);
  const [firstName, setFirstName] = useState(props.customer.firstName);
  const [lastName, setLastName] = useState(
    props.customer.lastName ? props.customer.lastName : ""
  );
  const [email, setEmail] = useState(
    props.customer.email ? props.customer.email : ""
  );
  const [birthDate, setBirthDate] = useState(
    props.customer.birthDate ? props.customer.birthDate : Utils.getDateToday()
  );
  const [status, setStatus] = useState(
    props.customer.status ? props.customer.status : ""
  );
  const [type, setType] = useState(
    props.customer.type ? props.customer.type : ""
  );
  const [contractEndDate, setContractEndDate] = useState(
    props.customer.contractEndDate ? props.customer.contractEndDate : ""
  );
  const [twitter, setTwitter] = useState(
    props.customer.twitter ? props.customer.twitter : ""
  );
  const [instagram, setInstagram] = useState(
    props.customer.instagram ? props.customer.instagram : ""
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
    setSuccessMessage("");
  };

  const handleFormSuccess = (successMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setSuccessMessage(successMessage);
    setErrorMessage("");
  };

  const submitBookingHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    if (!data.firstName) {
      handleFormError("First name is required.");
      return;
    }

    if (!data.status) {
      handleFormError("Status is required.");
      return;
    }

    if (data.type !== "REGULAR" && !data.contractEndDate) {
      handleFormError("Contract End Date is required.");
      return;
    }

    data.storeCode = authCtx.getStoreCode();

    updateCustomer(
      props.customer.id,
      JSON.stringify(data),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        handleFormSuccess("Customer info updated");
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError(error.message);
      });
  };

  return (
    <Fragment>
      <Grid columns={2}>
        <Grid.Column width={8} className="marginTop20">
          {errorMessage && (
            <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
          )}
          {successMessage && (
            <SuccessMessage successMessage={successMessage}></SuccessMessage>
          )}
          <Form onSubmit={submitBookingHandler}>
            <fieldset className="fieldset">
              <legend>Update Customer Form</legend>
              <Grid.Row columns={2} className="marginTop20">
                <Grid>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                  <Grid.Column width={4} className="paddingTop0">
                    <Label basic className="borderNone">
                      First Name
                    </Label>
                  </Grid.Column>

                  <Grid.Column width={10} className="paddingTop0">
                    <Input
                      type="text"
                      value={firstName}
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    ></Input>
                  </Grid.Column>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                  <Grid.Column width={4} className="paddingTop0">
                    <Label basic className="borderNone">
                      Status
                    </Label>
                  </Grid.Column>

                  <Grid.Column width={10} className="paddingTop0">
                    <select
                      id="status"
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ width: "180px" }}
                    >
                      {props.customerStatuses &&
                        props.customerStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                  <Grid.Column width={4} className="paddingTop0">
                    <Label basic className="borderNone">
                      Type
                    </Label>
                  </Grid.Column>

                  <Grid.Column width={10} className="paddingTop0">
                    <select
                      id="type"
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      style={{ width: "180px" }}
                    >
                      {props.customerTypes &&
                        props.customerTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid>
              </Grid.Row>

              {type !== "REGULAR" && (
                <Grid.Row columns={2}>
                  <Grid>
                    <Grid.Column
                      width={1}
                      className="paddingTop0"
                    ></Grid.Column>
                    <Grid.Column width={4} className="paddingTop0">
                      <Label basic className="borderNone">
                        Contract End Date
                      </Label>
                    </Grid.Column>

                    <Grid.Column width={10} className="paddingTop0">
                      <Input
                        id="contractEndDate"
                        name="contractEndDate"
                        type="date"
                        value={contractEndDate}
                        onChange={(e) => setContractEndDate(e.target.value)}
                        min={Utils.getDateToday()}
                        style={{ width: "180px" }}
                      />
                    </Grid.Column>
                    <Grid.Column width={1}></Grid.Column>
                  </Grid>
                </Grid.Row>
              )}

              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                  <Grid.Column width={4} className="paddingTop0">
                    <Label basic className="borderNone">
                      Twitter
                    </Label>
                  </Grid.Column>

                  <Grid.Column width={10} className="paddingTop0">
                    <Input
                      id="twitter"
                      name="twitter"
                      type="text"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                    <Icon
                      name="twitter"
                      size="big"
                      link
                      onClick={() =>
                        window.open(`https://www.twitter.com/${twitter}`)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Column width={1} className="paddingTop0"></Grid.Column>
                  <Grid.Column width={4} className="paddingTop0">
                    <Label basic className="borderNone">
                      Instagram
                    </Label>
                  </Grid.Column>

                  <Grid.Column width={10} className="paddingTop0">
                    <Input
                      id="instagram"
                      name="instagram"
                      type="text"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                    <Icon
                      name="instagram"
                      size="big"
                      link
                      onClick={() =>
                        window.open(`https://www.instagram.com/${instagram}`)
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid.Row className="marginBottom10">
                <Grid>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12} textAlign="center">
                    <Button primary>
                      <Icon name="pencil" />
                      UPDATE
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid>
              </Grid.Row>
            </fieldset>
          </Form>
        </Grid.Column>
        <Grid.Column width={8} className="marginTop20">
          <CustomerDetailsTable
            customer={props.customer}
            invokeSearch={props.invokeSearch}
          ></CustomerDetailsTable>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default UpdateCustomerForm;
