import { Fragment } from "react";

const AuditInfo = (props) => {
  return (
    <Fragment>
      <div style={{ textAlign: "right" }} className="marginTop20">
        <div style={{ fontSize: "10px" }}>
          {`Created by ${props.resource.createdBy} on ${props.resource.createdDateTime}`}
        </div>
        <div style={{ fontSize: "10px" }}>
          {`Last updated by ${props.resource.lastUpdatedBy} on ${props.resource.updatedDateTime}`}
        </div>
      </div>
    </Fragment>
  );
};

export default AuditInfo;
