import { Fragment, useState, useContext } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  Label,
  Input,
  Grid,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import AuditInfo from "../common/AuditInfo";
import { updatePromoApi, addPromoApi } from "../../api/api";

const PromoModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [name, setName] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedPromo.name : ""
  );
  const [isAvailable, setIsAvailable] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedPromo.available
      : true
  );

  const [hasUpdates, setHasUpdates] = useState(false);
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalPromoForm");
    if (form) {
      const formData = new FormData(form);

      const updateData = {};
      for (let [name, value] of formData.entries()) {
        updateData[name] = value;
      }

      if (!updateData.name) {
        handleFormError("Name is required and should be unique");
        return;
      }

      updateData["available"] = "true"; //isAvailable ? "true" : "false";

      updateData.storeCode = authCtx.getStoreCode();

      updatePromoApi(
        props.selectedPromo.id,
        JSON.stringify(updateData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Promo details updated");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  const handleAddButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalPromoForm");
    if (form) {
      const formData = new FormData(form);

      const data = {};
      for (let [name, value] of formData.entries()) {
        data[name] = value;
      }

      if (!data.name) {
        handleFormError("Name is required and should be unique");
        return;
      }

      data["available"] = "true"; //isAvailable ? "true" : "false";

      data.storeCode = authCtx.getStoreCode();

      addPromoApi(
        JSON.stringify(data),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Promo added");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  return (
    <Fragment>
      <Modal
        closeIcon
        size="tiny"
        onClose={handleOnCloseModal}
        open={props.isModalOpen}
      >
        <Modal.Header>
          {props.eventName === Utils.EVENT_UPDATE &&
            `Update Promo Details: ${props.selectedPromo.name}`}
          {props.eventName === Utils.EVENT_ADD && `Add Promo`}
        </Modal.Header>
        <Modal.Content>
          {modalFormErrorMessage && (
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          )}
          {modalFormSuccessMessage && (
            <SuccessMessage
              successMessage={modalFormSuccessMessage}
            ></SuccessMessage>
          )}
          <Form size="small" key="small" id="modalPromoForm">
            <Grid columns={2}>
              <Grid.Column width={4} className="marginTop10">
                <Label
                  basic
                  htmlFor="modalPromoName"
                  className="borderNone paddingRight0"
                >
                  Promo Name
                </Label>
              </Grid.Column>
              <Grid.Column width={12} className="paddingTop20">
                <Input
                  id="modalPromoName"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                ></Input>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />

            {/* <Grid columns={2}>
              <Grid.Column width={4}>
                <Label
                  basic
                  htmlFor="modalIsAvailable"
                  className="borderNone paddingRight0"
                >
                  Available
                </Label>
              </Grid.Column>
              <Grid.Column width={12} className="paddingTop20">
                <Checkbox
                  toggle
                  id="modalIsAvailable"
                  name="available"
                  className="marginTop10"
                  checked={isAvailable}
                  value={isAvailable ? "true" : "false"}
                  onChange={(e) => {
                    setIsAvailable(!isAvailable);
                  }}
                ></Checkbox>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted /> */}
          </Form>
          {props.eventName === Utils.EVENT_UPDATE && (
            <AuditInfo resource={props.selectedPromo}></AuditInfo>
          )}
        </Modal.Content>
        <Modal.Actions>
          {props.eventName === Utils.EVENT_UPDATE && (
            <Button color="blue" onClick={handleUpdateButton}>
              <Icon name="pencil" /> UPDATE
            </Button>
          )}
          {props.eventName === Utils.EVENT_ADD && (
            <Button color="blue" onClick={handleAddButton}>
              <Icon name="briefcase" /> SUBMIT
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default PromoModal;
