import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const LoaderComponent = (props) => {
  return (
    <React.Fragment>
      <Dimmer active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    </React.Fragment>
  );
};

export default LoaderComponent;
