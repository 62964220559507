import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const monthOptions = [
  { key: 1, text: "January", value: 1 },
  { key: 2, text: "February", value: 2 },
  { key: 3, text: "March", value: 3 },
  { key: 4, text: "April", value: 4 },
  { key: 5, text: "May", value: 5 },
  { key: 6, text: "June", value: 6 },
  { key: 7, text: "July", value: 7 },
  { key: 8, text: "August", value: 8 },
  { key: 9, text: "September", value: 9 },
  { key: 10, text: "October", value: 10 },
  { key: 11, text: "November", value: 11 },
  { key: 12, text: "December", value: 12 },
  { key: 13, text: "All", value: 13 },
];

const MonthDropdown = (props) => {
  const [selectedMonth, setSelectedMonth] = useState(props.month);

  const handleChange = (event, data) => {
    setSelectedMonth(data.value);
    props.handleMonthChange(data.value);
  };

  return (
    <Dropdown
      placeholder="Select Month"
      fluid={false}
      selection
      options={monthOptions}
      value={selectedMonth}
      onChange={handleChange}
    />
  );
};

export default MonthDropdown;
