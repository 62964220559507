import React, { Fragment, useState } from "react";
import { Button, Table, Icon } from "semantic-ui-react";
import LockerModal from "./LockerModal";
import Utils from "../../utils/Utils";

const LockersTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState([]);

  const itemsPerPage = 10; // Set the number of items per page
  const totalPages = Math.ceil(props.lockers.length / itemsPerPage); // Calculate total pages
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const lockersToDisplay = props.lockers.slice(startIndex, endIndex);

  const onClick = (locker) => {
    setIsModalOpen(true);
    setSelectedLocker(locker);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!lockersToDisplay || lockersToDisplay.length === 0 ? (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={2}>
                No lockers created.
              </Table.Cell>
            </Table.Row>
          ) : (
            lockersToDisplay.map((locker) => (
              <Table.Row key={locker.id}>
                <Table.Cell
                  className="pointer-on-hover"
                  onClick={() => onClick(locker)}
                  textAlign="center"
                >
                  {locker.name}
                </Table.Cell>
                <Table.Cell
                  className={locker.available ? "green" : "red"}
                  textAlign="center"
                >
                  {locker.available ? "Yes" : "No"}
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
      {lockersToDisplay.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            secondary
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Icon name="angle left" />
            Previous
          </Button>
          <span style={{ margin: "0 20px" }}>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            secondary
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
            <Icon name="angle right" />
          </Button>
        </div>
      )}

      {isModalOpen && (
        <LockerModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedLocker={selectedLocker}
          eventName={Utils.EVENT_UPDATE}
        />
      )}
    </Fragment>
  );
};

export default LockersTable;
