import React, {
  Fragment,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import RoomsTable from "../components/rooms/RoomsTable";
import AuthContext from "../store/auth-context";
import Utils from "../utils/Utils";
import RoomModal from "../components/rooms/RoomModal";
import { getRoomsApi, getServicesApi } from "../api/api";

const Rooms = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allRooms, setAllRooms] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchRooms = useCallback(async () => {
    try {
      const response = await getRoomsApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedRooms = [];
      for (const key in response) {
        loadedRooms.push(Utils.toRoom(response[key]));
      }
      setAllRooms(loadedRooms);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push(Utils.toService(response[key]));
      }
      setAllServices(loadedServices);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchRooms();
      fetchServices();
    }
  }, [fetchRooms, fetchServices, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <RoomsTable
            rooms={allRooms}
            services={allServices}
            handleOnCloseModal={handleOnCloseModal}
          ></RoomsTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <RoomModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD}
        />
      )}
    </Fragment>
  );
};

export default Rooms;
