import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Button,
  Modal,
  Icon,
  Divider,
  Label,
  Form,
  Input,
  TextArea,
  Grid,
  Checkbox,
} from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import {
  checkInAppointmentApi,
  updateAppointmentApi,
  completeAppointmentApi,
  cancelAppointmentApi,
  getCustomerByIdentifierApi,
} from "../../api/api";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import ProductModalSection from "../appointment/ProductModalSection";

const AppointmentModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [customer, setCustomer] = useState(null);
  const [staffId, setStaffId] = useState(props.selectedEvent.resourceId);
  const [roomId, setRoomId] = useState(props.selectedEvent.roomId);
  const [lockerId, setLockerId] = useState(props.selectedEvent.lockerId);
  const [note, setNote] = useState(props.selectedEvent.note);
  const [productsOrdered, setProductsOrdered] = useState(
    props.selectedEvent.productsOrdered
  );
  const [totalProductCost, setTotalProductCost] = useState(
    props.selectedEvent.productPaymentAmount
      ? props.selectedEvent.productPaymentAmount
      : 0
  );

  const [selectedPromoId, setSelectedPromoId] = useState(
    props.selectedEvent.promoId
  );
  const [pointsToRedeem, setPointsToRedeem] = useState(0);

  const [baseCheckInPaymentAmount, setBaseCheckInPaymentAmount] = useState(0);
  const [checkInPercentChargeAmount, setCheckInPercentChargeAmount] =
    useState(0);
  const [checkInPaymentAmount, setCheckInPaymentAmount] = useState(0);

  const [hasCheckInPercentCharge, setHasCheckInPercentCharge] = useState(false);

  const [additionalPaymentAmount, setAdditionalPaymentAmount] = useState(
    props.selectedEvent.additionalPaymentAmount
      ? props.selectedEvent.additionalPaymentAmount
      : 0
  );
  const [additionalPaymentMOP, setAdditionalPaymentMOP] = useState(
    props.selectedEvent.additionalPaymentMOP
      ? props.selectedEvent.additionalPaymentMOP
      : "CASH"
  );
  const [totalPriceToPayBeforeCompleted, setTotalPriceToPayBeforeCompleted] =
    useState("0");

  const [isCancelMode, setIsCancelMode] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [isCancelCustomerInitiated, setIsCancelCustomerInitiated] =
    useState(false);

  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");
  const [hasUpdates, setHasUpdates] = useState(false);

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    setHasUpdates(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormError = (errorMessage) => {
    setModalFormErrorMessage(errorMessage);
    setHasUpdates(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const isMassageAppointment =
    props.selectedEvent.serviceOfferedType === "MASSAGE";
  const staffName = isMassageAppointment
    ? Utils.getPropsName(props.selectedEvent.resourceId, props.allTherapists)
    : "";
  const lockerName = Utils.getPropsName(
    props.selectedEvent.lockerId,
    props.allLockers
  );
  const roomName = isMassageAppointment
    ? Utils.getPropsName(props.selectedEvent.roomId, props.allRooms)
    : "";
  const service = Utils.getObject(
    props.selectedEvent.serviceIds[0],
    props.services
  );
  const serviceName = service.name;
  const serviceCost = service.price;
  const customerName = props.selectedEvent.name;
  const isReserved = props.selectedEvent.status === "RESERVED";
  const isCheckedIn = props.selectedEvent.status === "CHECKED_IN";
  const isCompleted = props.selectedEvent.status === "COMPLETED";
  const isCancelled = props.selectedEvent.status === "CANCELLED";

  const updateProductDetails = (productsOrdered, totalProductCost) => {
    setProductsOrdered(productsOrdered);
    setTotalProductCost(totalProductCost);
  };

  const handleIncreaseAdditionalPaymentButton = (event) => {
    event.preventDefault();

    setAdditionalPaymentAmount((prevValue) => {
      if (!prevValue) {
        prevValue = 0;
      }
      return prevValue + props.additionalTowelPayment;
    });
  };

  const handlePrintButton = (event) => {
    event.preventDefault();
    window.print();
  };

  const handleCompleteButton = async (event) => {
    event.preventDefault();
    const form = document.getElementById("updateAppointmentForm");
    if (form) {
      const formData = new FormData(form);

      const bookingData = {};
      for (let [name, value] of formData.entries()) {
        bookingData[name] = value;
      }

      //complete payment
      bookingData.completeAppointmentPayment = {
        amount: props.selectedEvent.balanceAfterCheckIn,
        modeOfPayment: bookingData.additionalPaymentMOP,
      };

      bookingData.additionalPayment = {
        amount: 0,
        modeOfPayment: bookingData.additionalPaymentMOP,
      };

      bookingData.productsOrdered = productsOrdered;
      bookingData.productPayment = {
        amount: totalProductCost,
        modeOfPayment: bookingData.additionalPaymentMOP,
      };

      const notNeededFields = [
        "additionalPaymentAmount",
        "additionalPaymentMOP",
      ];

      for (const field of notNeededFields) {
        delete bookingData[field];
      }

      bookingData.storeCode = authCtx.getStoreCode();

      const body = JSON.stringify(bookingData);

      completeAppointmentApi(
        props.selectedEvent.id,
        body,
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Appointment status is COMPLETED.");
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  const handleCancelButton = async (event) => {
    event.preventDefault();
    setIsCancelMode(true);
  };

  const handleSubmitCancelButton = async (event) => {
    event.preventDefault();

    if (!cancelReason || cancelReason === "") {
      handleFormError("Cancellation Reason is required.");
      return;
    }

    const body = {
      cancelReason: cancelReason,
      customerInitiated: isCancelCustomerInitiated,
      storeCode: authCtx.getStoreCode(),
    };

    cancelAppointmentApi(
      props.selectedEvent.id,
      JSON.stringify(body),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        handleFormSuccess("Appointment status is CANCELLED.");
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError(error.message);
      });
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();

    const form = document.getElementById("updateAppointmentForm");
    if (form) {
      const formData = new FormData(form);

      // Access the form data and perform the necessary logic
      const bookingData = {};
      for (let [name, value] of formData.entries()) {
        bookingData[name] = value;
      }

      //roomId
      if (bookingData.roomId === "") {
        bookingData.roomId = null;
      }

      //lockerId
      if (bookingData.lockerId === "") {
        bookingData.lockerId = null;
      }

      //staffId
      if (bookingData.staffId === "") {
        bookingData.staffId = null;
      }

      //note
      if (bookingData.note === "") {
        bookingData.note = null;
      }

      bookingData.additionalPayment = {
        amount: 0,
        modeOfPayment: bookingData.additionalPaymentMOP,
      };

      bookingData.productsOrdered = productsOrdered;
      bookingData.productPayment = {
        amount: totalProductCost,
        modeOfPayment: bookingData.additionalPaymentMOP,
      };

      bookingData.storeCode = authCtx.getStoreCode();

      const notNeededFields = [
        "checkInPaymentAmount",
        "checkInPaymentMOP",
        "promoId",
        "pointsToRedeem",
      ];

      for (const field of notNeededFields) {
        delete bookingData[field];
      }

      updateAppointmentApi(
        props.selectedEvent.id,
        JSON.stringify(bookingData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Appointment details are UPDATED.");
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  const handleCheckInButton = (event) => {
    event.preventDefault();

    const form = document.getElementById("updateAppointmentForm");
    if (form) {
      const formData = new FormData(form);

      // Access the form data and perform the necessary logic
      const bookingData = {};
      for (let [name, value] of formData.entries()) {
        bookingData[name] = value;
      }

      if (
        isMassageAppointment &&
        (!bookingData.roomId || !bookingData.staffId)
      ) {
        handleFormError(
          "Room, Therapist and Locker are required when checking in for MASSAGE appointments"
        );
        return;
      }

      if (!bookingData.lockerId) {
        handleFormError("Locker is required when checking in");
        return;
      }

      //note
      if (bookingData.note === "") {
        bookingData.note = null;
      }

      if (bookingData.pointsToRedeem === "") {
        bookingData.pointsToRedeem = null;
      }

      bookingData.checkInPayment = {
        amount: bookingData.checkInPaymentAmount
          ? bookingData.checkInPaymentAmount
          : 0,
        modeOfPayment: bookingData.checkInPaymentMOP,
      };

      if (
        bookingData.checkInPaymentAmount &&
        bookingData.checkInPaymentAmount < 0
      ) {
        handleFormError("Payment should be greater than 0");
        return;
      }

      bookingData.checkInPercentChargeAmount = checkInPercentChargeAmount;
      bookingData.hasCheckInPercentCharge = hasCheckInPercentCharge;

      bookingData.status = "CHECKED_IN";
      bookingData.storeCode = authCtx.getStoreCode();

      const notNeededFields = [
        "additionalPaymentAmount",
        "additionalPaymentMOP",
        "checkInPaymentAmount",
        "checkInPaymentMOP",
      ];

      for (const field of notNeededFields) {
        delete bookingData[field];
      }

      checkInAppointmentApi(
        props.selectedEvent.id,
        JSON.stringify(bookingData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Appointment status is CHECKED-IN.");
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    }
  };

  const fetchCustomer = useCallback(
    async (id) => {
      try {
        const response = await getCustomerByIdentifierApi(
          id,
          false,
          false,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );

        const customer = {
          customerName: response.firstName.toUpperCase(),
          pointsEarned: response.pointsEarned,
        };

        setCustomer(customer);
      } catch (error) {
        console.log("error:", error);
      }
    },
    [authCtx]
  );

  useEffect(() => {
    if (props.selectedEvent.customerId && isReserved) {
      fetchCustomer(props.selectedEvent.customerId);
    }
  }, [isReserved, fetchCustomer, props.selectedEvent.customerId]);

  useEffect(() => {
    if (isReserved) {
      const promo = Utils.getObject(selectedPromoId, props.availablePromos);
      const balanceAfterReservation = props.selectedEvent
        .balanceAfterReservation
        ? props.selectedEvent.balanceAfterReservation
        : 0;
      const points = pointsToRedeem ? pointsToRedeem : 0;
      const serviceId = props.selectedEvent.serviceIds[0];
      const promoLess =
        selectedPromoId &&
        serviceId &&
        promo.serviceLessPriceMap &&
        promo.serviceLessPriceMap[serviceId] !== ""
          ? promo.serviceLessPriceMap[serviceId]
          : 0;
      setCheckInPaymentAmount(balanceAfterReservation - points - promoLess);
      setBaseCheckInPaymentAmount(balanceAfterReservation - points - promoLess);
    }
  }, [
    isReserved,
    pointsToRedeem,
    isCheckedIn,
    selectedPromoId,
    props.availablePromos,
    props.selectedEvent.serviceIds,
    props.selectedEvent.balanceAfterReservation,
  ]);

  useEffect(() => {
    setTotalPriceToPayBeforeCompleted(
      (additionalPaymentAmount ? additionalPaymentAmount : 0) +
        (props.selectedEvent.balanceAfterCheckIn
          ? props.selectedEvent.balanceAfterCheckIn
          : 0) +
        totalProductCost
    );
  }, [
    additionalPaymentAmount,
    props.selectedEvent.balanceAfterCheckIn,
    totalProductCost,
  ]);

  useEffect(() => {
    props.fetchAvailableResources({
      startTime: props.selectedEvent.startTime,
      startDate: props.selectedEvent.startDate,
      appointmentId: props.selectedEvent.id,
      serviceId: props.selectedEvent.serviceIds[0],
    });
  }, []);

  const handleModalClose = () => {
    props.handleModalClose(hasUpdates);
  };

  return (
    <Modal
      closeIcon
      open={props.isModalOpen}
      onClose={handleModalClose}
      size="fullscreen"
    >
      <Modal.Header>
        <h1>
          {isMassageAppointment
            ? `C ${customerName} / T ${staffName} / R${roomName} / L${lockerName} / ${serviceName}`
            : `C ${customerName} / L${lockerName} / ${serviceName}`}
        </h1>
      </Modal.Header>
      <Modal.Content>
        {modalFormSuccessMessage && (
          <SuccessMessage
            successMessage={modalFormSuccessMessage}
          ></SuccessMessage>
        )}
        {modalFormErrorMessage && (
          <div className="marginLeft10 marginBottom10">
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          </div>
        )}
        <Form id="updateAppointmentForm" size="small" key="small">
          <Grid columns={3}>
            <Grid.Column width={5}>
              {isCancelled && (
                <fieldset className="fieldset">
                  <legend>Cancellation Details</legend>
                  <Grid columns={2}>
                    <Grid.Column width={6}>
                      <Label
                        basic
                        htmlFor="modalCancelReason"
                        className="borderNone paddingRight0"
                      >
                        Cancellation Reason
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={10} className="paddingTop25">
                      {props.selectedEvent.cancelReason
                        ? props.selectedEvent.cancelReason
                        : Utils.BLANK}
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2}>
                    <Grid.Column width={6}>
                      <Label
                        basic
                        htmlFor="modalCancelReason"
                        className="borderNone paddingRight0"
                      >
                        Customer Cancelled or Did Not Show Up
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={10} className="paddingTop25">
                      {props.selectedEvent.customerInitiatedCancel
                        ? "Yes"
                        : "No"}
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid>
                    <Grid.Column>
                      <p style={{ textAlign: "justify", fontStyle: "italic" }}>
                        <Icon name="exclamation" color="red" size="big"></Icon>
                        Appointment is CANCELLED. You can't update this
                        appointment. Cancelled appointments will not be part of
                        profit and commission computations in report.
                      </p>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />
                </fieldset>
              )}

              <fieldset className="fieldset">
                <legend>Customer</legend>
                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalMobileNumber"
                      className="borderNone paddingRight0"
                    >
                      Customer Mobile No.
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop25">
                    <span id="modalMobileNumber">
                      {props.selectedEvent.mobileNumber}
                    </span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalCustomerName"
                      className="borderNone paddingRight0"
                    >
                      Customer Name
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop20">
                    <span id="modalCustomerName">{customerName}</span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </fieldset>

              <fieldset className="fieldset">
                <legend>Service</legend>
                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="startDateTime"
                      className="borderNone paddingRight0"
                    >
                      Start
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop25">
                    {`${Utils.convertToAMPM(
                      props.selectedEvent.startTime
                    )} ${Utils.convertToDescriptiveDate(
                      props.selectedEvent.startDate
                    )}`}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                {isMassageAppointment && (
                  <Fragment>
                    <Grid columns={2} verticalAlign="middle">
                      <Grid.Column width={4}>
                        <Label
                          basic
                          htmlFor="endDateTime"
                          className="borderNone paddingRight0"
                        >
                          <div>End</div>
                          <div>
                            <sub>cleaning time included</sub>
                          </div>
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop25">
                        {`${Utils.convertToAMPM(
                          props.selectedEvent.endTime
                        )} ${Utils.convertToDescriptiveDate(
                          props.selectedEvent.endDate
                        )}`}
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </Fragment>
                )}

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label basic className="borderNone" htmlFor="serviceId">
                      Service
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {`${serviceName} (PHP${serviceCost})`}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                {isMassageAppointment && (
                  <Fragment>
                    <Grid columns={2} verticalAlign="middle">
                      <Grid.Column width={4}>
                        <Label
                          htmlFor="staffId"
                          basic
                          className="borderNone paddingRight0"
                        >
                          Therapist
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        {props.selectedEvent.status === "RESERVED" ? (
                          <select
                            id="staffId"
                            name="staffId"
                            value={staffId}
                            onChange={(e) => setStaffId(e.target.value)}
                          >
                            <option value="">{Utils.BLANK}</option>
                            <optgroup label="OPENING">
                              {props.availableTherapists &&
                                props.availableTherapists
                                  .filter(
                                    (staff) =>
                                      staff.staffScheduleType === "OPENING"
                                  )
                                  .map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                      {staff.name.toUpperCase()}
                                    </option>
                                  ))}
                            </optgroup>
                            <optgroup label="MID">
                              {props.availableTherapists &&
                                props.availableTherapists
                                  .filter(
                                    (staff) => staff.staffScheduleType === "MID"
                                  )
                                  .map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                      {staff.name.toUpperCase()}
                                    </option>
                                  ))}
                            </optgroup>
                            <optgroup label="CLOSING">
                              {props.availableTherapists &&
                                props.availableTherapists
                                  .filter(
                                    (staff) =>
                                      staff.staffScheduleType === "CLOSING"
                                  )
                                  .map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                      {staff.name.toUpperCase()}
                                    </option>
                                  ))}
                            </optgroup>
                          </select>
                        ) : (
                          staffName
                        )}
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4}>
                        <Label
                          htmlFor="isTherapistRequested"
                          className="borderNone"
                          basic
                        >
                          Therapist Requested
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop25">
                        {props.selectedEvent.staffRequested ? "Yes" : "No"}
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2} verticalAlign="middle">
                      <Grid.Column width={4}>
                        <Label
                          htmlFor="roomId"
                          basic
                          className="borderNone paddingRight0"
                        >
                          Room No.
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        {props.selectedEvent.status === "RESERVED" ? (
                          <select
                            id="roomId"
                            name="roomId"
                            value={roomId}
                            onChange={(e) => setRoomId(e.target.value)}
                          >
                            <option value="">{Utils.BLANK}</option>
                            {props.availableRooms &&
                              props.availableRooms.map((room) => (
                                <option key={room.id} value={room.id}>
                                  {room.name}
                                </option>
                              ))}
                          </select>
                        ) : (
                          roomName
                        )}
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </Fragment>
                )}

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      htmlFor="lockerId"
                      basic
                      className="borderNone paddingRight0"
                    >
                      Locker No.
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {isReserved ? (
                      <select
                        id="lockerId"
                        name="lockerId"
                        required
                        value={lockerId}
                        onChange={(e) => setLockerId(e.target.value)}
                      >
                        <option value="">{Utils.BLANK}</option>
                        {props.availableLockers &&
                          props.availableLockers.map((locker) => (
                            <option key={locker.id} value={locker.id}>
                              {locker.name}
                            </option>
                          ))}
                      </select>
                    ) : (
                      lockerName
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </fieldset>

              <fieldset className="fieldset">
                <legend>Other Details</legend>
                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalType"
                      className="borderNone paddingRight0"
                    >
                      Booking Channel
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span id="modalType">{props.selectedEvent.type}</span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalNote"
                      className="borderNone paddingRight0"
                    >
                      Note
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {isCompleted || isCancelled ? (
                      props.selectedEvent.note ? (
                        props.selectedEvent.note
                      ) : (
                        Utils.BLANK
                      )
                    ) : (
                      <TextArea
                        id="modalNote"
                        name="note"
                        type="text"
                        placeholder="Note"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      />
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalStatus"
                      className="borderNone paddingRight0"
                    >
                      Status
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span>{props.selectedEvent.status}</span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </fieldset>
            </Grid.Column>
            <Grid.Column width={6}>
              <fieldset className="fieldset">
                <legend>Appointment Creation Payment</legend>

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAppointmentCreationPayment"
                      className="borderNone paddingRight0"
                    >
                      Appointment Creation Payment
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span className="amountText">
                      {`PHP ${
                        props.selectedEvent.appointmentCreationPaymentAmount
                      } 
                      ${
                        props.selectedEvent.appointmentCreationPaymentAmount &&
                        props.selectedEvent.appointmentCreationPaymentAmount !==
                          0
                          ? `(${props.selectedEvent.appointmentCreationPaymentMOP})`
                          : ""
                      }`}
                    </span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalBalanceBeforeCheckIn"
                      className="borderNone paddingRight0"
                    >
                      Balance After Reservation
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span className="amountText">
                      {`PHP ${props.selectedEvent.balanceAfterReservation}`}
                    </span>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </fieldset>

              <fieldset className="fieldset">
                <legend>Payment Before Check-In</legend>
                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="promoId"
                      className="borderNone paddingRight0"
                    >
                      Promo
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {isReserved ? (
                      <Fragment>
                        <select
                          id="promoId"
                          name="promoId"
                          value={selectedPromoId}
                          onChange={(e) => {
                            setSelectedPromoId(e.target.value);
                            setHasCheckInPercentCharge(false);
                            setCheckInPercentChargeAmount(0);
                          }}
                        >
                          <option value="">{Utils.BLANK}</option>
                          {props.availablePromos &&
                            props.availablePromos.map((promo) => (
                              <option key={promo.id} value={promo.id}>
                                {promo.name.toUpperCase()}
                              </option>
                            ))}
                        </select>
                      </Fragment>
                    ) : props.selectedEvent.promoId ? (
                      `${Utils.getPropsName(
                        props.selectedEvent.promoId,
                        props.availablePromos
                      )} (less PHP${props.selectedEvent.discount})`
                    ) : (
                      Utils.BLANK
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="pointsToRedeem"
                      className="borderNone paddingRight0"
                    >
                      Redeem Loyalty Points
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="marginTop10">
                    {isReserved &&
                    customer &&
                    customer.pointsEarned >=
                      props.store.loyaltyMinimumPointsToRedeem ? (
                      <select
                        id="pointsToRedeem"
                        name="pointsToRedeem"
                        value={pointsToRedeem}
                        onChange={(e) => {
                          setPointsToRedeem(e.target.value);
                          setHasCheckInPercentCharge(false);
                          setCheckInPercentChargeAmount(0);
                        }}
                      >
                        <option value="">{Utils.BLANK}</option>
                        {Utils.generatePointsToRedeemDropdown(
                          props.store.loyaltyMinimumPointsToRedeem,
                          props.store.loyaltyPointsToRedeemDivisibleBy,
                          parseInt(customer.pointsEarned)
                        )}
                      </select>
                    ) : props.selectedEvent.pointsRedeemed ? (
                      props.selectedEvent.pointsRedeemed
                    ) : (
                      Utils.BLANK
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalCheckInPaymentAmount"
                      className="borderNone paddingRight0"
                    >
                      Check-In Payment Amount
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {isReserved ? (
                      <Input
                        min="0"
                        type="number"
                        required
                        id="modalCheckInPaymentAmount"
                        name="checkInPaymentAmount"
                        step="any"
                        value={checkInPaymentAmount}
                        onChange={(e) =>
                          setCheckInPaymentAmount(e.target.value)
                        }
                        className={
                          checkInPaymentAmount < 0 ? "negative-input" : ""
                        }
                      ></Input>
                    ) : props.selectedEvent.checkInPaymentAmount ? (
                      <span className="amountText">
                        {`PHP ${props.selectedEvent.checkInPaymentAmount} (${props.selectedEvent.checkInPaymentMOP})`}
                      </span>
                    ) : (
                      Utils.BLANK
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                {isReserved && (
                  <Fragment>
                    <Grid columns={2} verticalAlign="middle">
                      <Grid.Column width={4}>
                        <Label
                          basic
                          htmlFor="modalCheckInPaymentMOP"
                          className="borderNone paddingRight0"
                        >
                          Mode of Payment (MOP)
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <select
                          id="modalCheckInPaymentMOP"
                          name="checkInPaymentMOP"
                        >
                          {props.modeOfPayments &&
                            props.modeOfPayments.map((mode) => (
                              <option key={mode} value={mode}>
                                {mode}
                              </option>
                            ))}
                        </select>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </Fragment>
                )}

                <Grid columns={2} verticalAlign="middle">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalHasPercentCharge"
                      className="borderNone paddingRight0"
                    >
                      3% Charge
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    {isReserved ? (
                      <Checkbox
                        toggle
                        id="modalHasPercentCharge"
                        name="hasCheckInPercentCharge"
                        className="marginTop10"
                        checked={hasCheckInPercentCharge}
                        onChange={(e) => {
                          setHasCheckInPercentCharge(e.target.checked);
                          if (e.target.checked) {
                            setCheckInPaymentAmount(
                              baseCheckInPaymentAmount +
                                baseCheckInPaymentAmount * 0.03
                            );
                            setCheckInPercentChargeAmount(
                              baseCheckInPaymentAmount * 0.03
                            );
                          } else {
                            setCheckInPaymentAmount(baseCheckInPaymentAmount);
                            setCheckInPercentChargeAmount(0);
                          }
                        }}
                      ></Checkbox>
                    ) : props.selectedEvent.hasCheckInPercentCharge &&
                      props.selectedEvent.hasCheckInPercentCharge ? (
                      `PHP ${props.selectedEvent.checkInPercentChargeAmount}`
                    ) : (
                      Utils.BLANK
                    )}
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </fieldset>

              {isCheckedIn && (
                <fieldset className="fieldset">
                  <legend>Payment Before Completed</legend>

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalBalanceAfterCheckIn"
                        className="borderNone paddingRight0"
                      >
                        Balance After Check-In
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <div>
                        <span
                          style={{ fontSize: "20px", fontWeight: "bolder" }}
                          id="modalBalanceAfterCheckIn"
                        >{`PHP ${
                          props.selectedEvent.balanceAfterCheckIn
                            ? props.selectedEvent.balanceAfterCheckIn
                            : 0
                        }`}</span>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalTotalPriceToPayBeforeCompleted"
                        className="borderNone paddingRight0"
                      >
                        Total Balance Before Completed
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <span
                        id="modalTotalPriceToPayBeforeCompleted"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bolder",
                        }}
                      >{`PHP ${totalPriceToPayBeforeCompleted}`}</span>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalAdditionalMOP"
                        className="borderNone paddingRight0"
                      >
                        Mode of Payment (MOP)
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <select
                        id="modalAdditionalMOP"
                        name="additionalPaymentMOP"
                        value={additionalPaymentMOP}
                        onChange={(event) =>
                          setAdditionalPaymentMOP(event.target.value)
                        }
                      >
                        {props.modeOfPayments &&
                          props.modeOfPayments.map((mode) => (
                            <option key={mode} value={mode}>
                              {mode}
                            </option>
                          ))}
                      </select>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />
                </fieldset>
              )}

              {isCompleted || isCancelled ? (
                <fieldset className="fieldset">
                  <legend>Payment Before Completed</legend>

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalBalanceAfterCheckIn"
                        className="borderNone paddingRight0"
                      >
                        Balance After Check-In
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <div>
                        <span
                          style={{ fontSize: "20px", fontWeight: "bolder" }}
                          id="modalBalanceAfterCheckIn"
                        >{`PHP ${
                          props.selectedEvent.balanceAfterCheckIn
                            ? props.selectedEvent.balanceAfterCheckIn
                            : 0
                        }`}</span>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalAdditionalPayment"
                        className="borderNone paddingRight0"
                      >
                        Additional Payment
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <span className="price">{`PHP ${additionalPaymentAmount} ${
                        props.selectedEvent.additionalPaymentMOP &&
                        additionalPaymentAmount !== 0
                          ? `(${props.selectedEvent.additionalPaymentMOP})`
                          : ""
                      }`}</span>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2} verticalAlign="middle">
                    <Grid.Column width={4}>
                      <Label
                        basic
                        htmlFor="modalTotalPricePaid"
                        className="borderNone paddingRight0"
                      >
                        <div>Total Payment</div>
                        <div>
                          <sub>Service + Products</sub>
                        </div>
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <span className="price">
                        {`PHP ${
                          props.selectedEvent.totalPricePaid
                            ? props.selectedEvent.totalPricePaid
                            : 0
                        }`}
                      </span>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />
                </fieldset>
              ) : (
                <Fragment></Fragment>
              )}

              {isCancelMode && (
                <fieldset className="fieldset">
                  <legend>
                    Cancellation Details (Fill out details then Click SUBMIT)
                  </legend>
                  <Grid columns={2}>
                    <Grid.Column width={5}>
                      <Label
                        basic
                        htmlFor="modalCancelReason"
                        className="borderNone paddingRight0"
                      >
                        Cancellation Reason
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <TextArea
                        required
                        id="modalCancelReason"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                      ></TextArea>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />

                  <Grid columns={2}>
                    <Grid.Column width={5}>
                      <Label
                        htmlFor="modalIsCancelCustomerInitiated"
                        basic
                        className="borderNone paddingRight0"
                      >
                        Customer Cancelled or Did Not Show Up
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={11} className="marginTop10">
                      <Checkbox
                        toggle
                        id="modalIsCancelCustomerInitiated"
                        name="isCancelCustomerInitiated"
                        checked={isCancelCustomerInitiated}
                        onChange={(e) => {
                          setIsCancelCustomerInitiated(e.target.checked);
                        }}
                      ></Checkbox>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden />

                  <Grid>
                    <Grid.Column>
                      <p style={{ textAlign: "justify", fontStyle: "italic" }}>
                        <Icon name="exclamation" color="red" size="big"></Icon>
                        Clicking SUBMIT will mark the appointment CANCELLED.
                        Cancelled appointments will not be part of profit and
                        commission computations in report.
                      </p>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden fitted />
                </fieldset>
              )}
            </Grid.Column>

            <ProductModalSection
              productTypes={props.productTypes}
              productsOrdered={productsOrdered}
              totalProductCost={totalProductCost}
              updateProductDetails={updateProductDetails}
              selectedEvent={props.selectedEvent}
            ></ProductModalSection>
          </Grid>
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <Button onClick={handlePrintButton} color="grey">
            <Icon name="print" /> PRINT
          </Button>

          <div style={{ textAlign: "right" }}>
            <div style={{ fontSize: "10px" }}>
              Appointment ID: {props.selectedEvent.id}
            </div>
            <div style={{ fontSize: "10px" }}>
              {`Created by ${props.selectedEvent.createdBy} on ${props.selectedEvent.createdDateTime}`}
            </div>
            <div style={{ fontSize: "10px" }}>
              {`Last updated by ${props.selectedEvent.lastUpdatedBy} on ${props.selectedEvent.updatedDateTime}`}
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {!isCancelled && (
          <Fragment>
            {isCancelMode ? (
              <div>
                <Button color="blue" onClick={() => setIsCancelMode(false)}>
                  BACK
                </Button>
                <Button color="blue" onClick={handleSubmitCancelButton}>
                  SUBMIT
                </Button>
              </div>
            ) : (
              <div style={{ marginRight: "10px" }}>
                {!isCompleted && (
                  <Button color="blue" onClick={handleUpdateButton}>
                    <Icon name="pencil" /> UPDATE
                  </Button>
                )}

                {isCheckedIn && (
                  <Button onClick={handleCompleteButton} color="blue">
                    <Icon name="check" /> COMPLETE
                  </Button>
                )}

                {isReserved && (
                  <Button color="blue" onClick={handleCheckInButton}>
                    <Icon name="bed" /> CHECK-IN
                  </Button>
                )}

                <Button onClick={handleCancelButton} color="red">
                  <Icon name="exclamation circle" /> CANCEL APPOINTMENT
                </Button>
              </div>
            )}
          </Fragment>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default AppointmentModal;
