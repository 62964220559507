import { Fragment, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import Utils from "../../utils/Utils";
import AppointmentModal from "./AppointmentModal";

const AppointmentsTable = (props) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectEvent = (event) => {
    setIsModalOpen(true);
    setSelectedEvent(event);
  };

  const handleModalClose = (needsReload) => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    props.handleOnCloseModal(needsReload);
  };

  const blank = "-";

  return (
    <Fragment>
      <Grid>
        <Grid.Row className="marginTop10 marginRight25">
          <div style={{ maxHeight: "800px", overflow: "auto" }}>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Customer</Table.HeaderCell>
                  <Table.HeaderCell>Locker No.</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Customer Mobile No.</Table.HeaderCell>
                  <Table.HeaderCell>Start Date</Table.HeaderCell>
                  <Table.HeaderCell>Start Time</Table.HeaderCell>
                  <Table.HeaderCell>Service</Table.HeaderCell>
                  <Table.HeaderCell>Note</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!props.appointments || props.appointments.length === 0 ? (
                  <Table.Row>
                    <Table.Cell textAlign="center" colSpan={9}>
                      No appointments
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  props.appointments
                    .filter((appointment) => !appointment.resourceId)
                    .map((appointment) => {
                      return (
                        <Table.Row key={appointment.id}>
                          <Table.Cell
                            className="pointer-on-hover"
                            onClick={() => handleSelectEvent(appointment)}
                          >
                            {appointment.name}
                          </Table.Cell>
                          <Table.Cell
                            className="pointer-on-hover"
                            onClick={() => handleSelectEvent(appointment)}
                          >
                            {Utils.getPropsName(
                              appointment.lockerId,
                              props.allLockers
                            )}
                          </Table.Cell>
                          <Table.Cell
                            className="pointer-on-hover"
                            onClick={() => handleSelectEvent(appointment)}
                            style={{
                              backgroundColor:
                                appointment.status === "RESERVED"
                                  ? "#B1F2FF"
                                  : appointment.status === "CHECKED_IN"
                                  ? "#FCF4A3"
                                  : appointment.status === "COMPLETED"
                                  ? "#A7F1A8"
                                  : appointment.status === "CANCELLED"
                                  ? "#FFCCCB"
                                  : "grey",
                              color: "black",
                            }}
                          >
                            {appointment.status.replace("_", "-")}
                          </Table.Cell>
                          <Table.Cell>{appointment.mobileNumber}</Table.Cell>
                          <Table.Cell>
                            {Utils.convertToDescriptiveDate(
                              appointment.startDate
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {Utils.convertToAMPM(appointment.startTime)}
                          </Table.Cell>
                          <Table.Cell>
                            {Utils.getPropsName(
                              appointment.serviceIds[0],
                              props.availableServices
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {appointment.status === "CANCELLED"
                              ? appointment.cancelReason
                                ? appointment.cancelReason
                                : blank
                              : appointment.note
                              ? appointment.note
                              : blank}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                )}
              </Table.Body>
            </Table>
          </div>
        </Grid.Row>
      </Grid>
      {selectedEvent && (
        <AppointmentModal
          store={props.store}
          fetchAvailableResources={props.fetchAvailableResources}
          handleModalClose={handleModalClose}
          isModalOpen={isModalOpen}
          selectedEvent={selectedEvent}
          services={props.services}
          availableServices={props.availableServices}
          availablePromos={props.availablePromos}
          allLockers={props.allLockers}
          availableLockers={props.availableLockers}
          allRooms={props.allRooms}
          availableRooms={props.availableRooms}
          allTherapists={props.allTherapists}
          availableTherapists={props.availableTherapists}
          appointmentTypes={props.appointmentTypes}
          appointmentStatuses={props.appointmentStatuses}
          appointmentStartTimes={props.appointmentStartTimes}
          modeOfPayments={props.modeOfPayments}
          isMassageAppointment={props.isMassageAppointment}
          additionalTowelPayment={props.additionalTowelPayment}
          productTypes={props.productTypes}
        ></AppointmentModal>
      )}
    </Fragment>
  );
};

export default AppointmentsTable;
