import React, { Fragment, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentModal from "./AppointmentModal";
import Utils from "../../utils/Utils";
import moment from "moment";

const localizer = momentLocalizer(moment);

const AppointmentCalendar = (props) => {
  const [today, setToday] = useState(new Date(props.currentView));
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState(props.events);

  const handleSelectEvent = (event) => {
    if (event.title === "ON BREAK") {
      return;
    }
    setIsModalOpen(true);
    setSelectedEvent(event);
  };

  const handleModalClose = (needsReload) => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    props.handleOnCloseModal(needsReload);
  };

  useEffect(() => {
    let onBreak1Event = [];
    if (props.onBreak1) {
      for (let index = 0; index < props.onBreak1.length; index++) {
        onBreak1Event.push({
          id: props.onBreak1[index].id,
          resourceId: props.onBreak1[index].id,
          title: "ON BREAK",
          start: new Date(props.onBreak1[index].start),
          end: new Date(props.onBreak1[index].end),
        });
      }
    }

    let onBreak2Event = [];
    if (props.onBreak2) {
      for (let index = 0; index < props.onBreak2.length; index++) {
        onBreak2Event.push({
          id: props.onBreak2[index].id,
          resourceId: props.onBreak2[index].id,
          title: "ON BREAK",
          start: new Date(props.onBreak2[index].start),
          end: new Date(props.onBreak2[index].end),
        });
      }
    }

    if (props.events) {
      let modifiedEvents = props.events.map((event) => {
        const staffName = props.isMassageAppointment
          ? Utils.getPropsName(event.resourceId, props.allTherapists)
          : "";
        const lockerName = Utils.getPropsName(event.lockerId, props.allLockers);
        const roomName = props.isMassageAppointment
          ? Utils.getPropsName(event.roomId, props.allRooms)
          : "";
        const customerName = event.name;
        const serviceName = Utils.getPropsName(
          event.serviceIds[0],
          props.services
        );
        const title = props.isMassageAppointment
          ? `C ${customerName} / T ${staffName} / R${roomName} / L${lockerName} / ${serviceName}`
          : `C ${customerName} / L ${lockerName} / ${serviceName}`;
        if (event.resourceId === null) {
          return { ...event, resourceId: "no-therapist", title: title };
        }
        return { ...event, title: title };
      });
      setCalendarEvents([
        ...onBreak1Event,
        ...onBreak2Event,
        ...modifiedEvents,
      ]);
    }
  }, [props.events, props.onBreak1, props.onBreak2]);

  const handleSlotSelect = ({ start, end }) => {
    // Handle the click event for the selected time slot
    console.log("Selected slot:", start, end);
    // Perform any desired actions or update the state accordingly
  };

  const onNavigate = () => {
    console.log("on navigate placeholder");
  };

  useEffect(() => {
    let view = new Date(props.currentView);
    setToday(view);

    var currentDateAndTime = new Date();
    // Extract date components from dateTime and serverTime
    var viewDateTime = new Date(
      view.getFullYear(),
      view.getMonth(),
      view.getDate()
    );
    var serverTimeDateOnly = new Date(
      currentDateAndTime.getFullYear(),
      currentDateAndTime.getMonth(),
      currentDateAndTime.getDate()
    );

    if (viewDateTime >= serverTimeDateOnly) {
      var hours = currentDateAndTime.getHours();
      var minutes = currentDateAndTime.getMinutes();
      var formattedHours = hours < 10 ? "0" + hours : hours;
      var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      var formattedDateAndTime = formattedHours + ":" + formattedMinutes;

      var startDate = view.toISOString().split("T")[0];

      props.fetchUnavailableResources({
        startTime: formattedDateAndTime,
        startDate: startDate,
      });
    }
  }, [props.currentView]);

  return (
    <Fragment>
      <Calendar
        toolbar={false}
        localizer={localizer}
        events={calendarEvents}
        resources={[
          { id: "no-therapist", name: "No Therapist Assigned" },
          ...props.therapistsOnDuty,
        ]}
        showMultiDayTimes
        resourceIdAccessor="id"
        resourceTitleAccessor="name"
        views={["day", "resourceDay"]}
        date={today}
        defaultDate={today}
        defaultView="day"
        titleAccessor="title"
        startAccessor="start"
        endAccessor="end"
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)
        }
        max={
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            23,
            59
          )
        }
        style={{ height: "1500px" }}
        selectable
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSlotSelect}
        onNavigate={onNavigate}
        eventPropGetter={(event, start, end, isSelected) => {
          let backgroundColor = "grey";
          let color = "white";
          switch (event.status) {
            case "RESERVED":
              backgroundColor = "#B1F2FF";
              color = "black";
              break;
            case "CHECKED_IN":
              backgroundColor = "#FCF4A3";
              color = "black";
              break;
            case "COMPLETED":
              backgroundColor = "#A7F1A8";
              color = "black";
              break;
            case "CANCELLED":
              backgroundColor = "#FFCCCB";
              color = "black";
              break;
            default:
              backgroundColor = "#DEDEDE";
              color = "black";
              break;
          }
          return {
            style: {
              backgroundColor,
              border: "solid",
              fontWeight: "bolder",
              color,
            },
          };
        }}
        step={30}
      />

      {selectedEvent && (
        <AppointmentModal
          store={props.store}
          fetchAvailableResources={props.fetchAvailableResources}
          fetchUnavailableResources={props.fetchUnavailableResources}
          handleModalClose={handleModalClose}
          isModalOpen={isModalOpen}
          selectedEvent={selectedEvent}
          services={props.services}
          availableServices={props.availableServices}
          allLockers={props.allLockers}
          availableLockers={props.availableLockers}
          allRooms={props.allRooms}
          availableRooms={props.availableRooms}
          allTherapists={props.allTherapists}
          availableTherapists={props.availableTherapists}
          availablePromos={props.availablePromos}
          appointmentTypes={props.appointmentTypes}
          appointmentStatuses={props.appointmentStatuses}
          appointmentStartTimes={props.appointmentStartTimes}
          modeOfPayments={props.modeOfPayments}
          additionalTowelPayment={props.additionalTowelPayment}
          isMassageAppointment={props.isMassageAppointment}
          productTypes={props.productTypes}
        ></AppointmentModal>
      )}
    </Fragment>
  );
};

export default AppointmentCalendar;
