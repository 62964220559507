import React, { Fragment, useContext, useState } from "react";
import { Grid, Button, Label, Form, Icon } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import MonthDropdown from "../common/MonthDropdown";
import YearDropdown from "../common/YearDropdown";
import ErrorMessage from "../common/ErrorMessage";

const TherapistStatisticsReportForm = () => {
  const authCtx = useContext(AuthContext);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Function to handle month selection change
  const handleMonthChange = (value) => {
    setMonth(value);
    console.log(value);
  };

  // Function to handle year selection change
  const handleYearChange = (value) => {
    setYear(value);
    console.log(value);
  };

  const submitBookingHandler = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setDisableButton(true);

    // Check if month and year are selected
    if (!month || !year) {
      handleFormError("Please select both month and year");
      setDisableButton(false);
      return;
    }

    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND_API
      }/v1/reports/staffs/download?month=${month}&year=${year}&storeCode=${authCtx.getStoreCode()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          STORE_CODE: authCtx.getStoreCode(),
          Authorization: `Bearer ${authCtx.getToken()}`,
        },
      }
    );

    if (response.ok) {
      let storeCode = authCtx.getStoreCode().replaceAll("_", "");
      let monthString = month === 13 ? "ALL" : month;
      const filename = `TherapistStatistics_${monthString}_${year}_${storeCode}.xlsx`;

      setDisableButton(false);

      return response.blob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      handleFormError("Report generation failed");
    }

    setDisableButton(false);
  };

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
  };

  return (
    <Fragment>
      <Form onSubmit={submitBookingHandler}>
        <Grid>
          {errorMessage && (
            <Grid.Row>
              <Grid.Column width="5">
                <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width="4">
              <Label basic className="borderNone">
                Month
              </Label>
              <MonthDropdown
                handleMonthChange={handleMonthChange}
                month={month}
              ></MonthDropdown>
            </Grid.Column>
            <Grid.Column width="4">
              <Label basic className="borderNone">
                Year:
              </Label>
              <YearDropdown
                handleYearChange={handleYearChange}
                year={year}
              ></YearDropdown>
            </Grid.Column>
            <Grid.Column width="8">
              <Button disabled={disableButton} primary>
                <Icon name="download" />
                DOWNLOAD
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Fragment>
  );
};

export default TherapistStatisticsReportForm;
