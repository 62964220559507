import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogout: () => {},
  onLogin: () => {},
  getToken: () => {},
  getStoreCode: () => {},
  getRoles: () => [],
});

export const AuthContextProvider = (props) => {
  useEffect(() => {
    const storedUserLoggedInInfo = localStorage.getItem("isLoggedIn");

    if (storedUserLoggedInInfo === "1") {
      setIsLoggedIn(true);
    }
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [roles, setRoles] = useState([]);

  const logoutHandler = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("storeCode");
    localStorage.removeItem("roles");
    setIsLoggedIn(false);
    setToken("");
    setStoreCode("");
    setRoles("");
  };

  const loginHandler = (token, storeCode, roles) => {
    localStorage.setItem("isLoggedIn", "1");
    localStorage.setItem("token", token);
    localStorage.setItem("storeCode", storeCode);
    localStorage.setItem("roles", roles);
    setIsLoggedIn(true);
    setToken(token);
    setStoreCode(storeCode);
    setRoles(roles);
  };

  const getToken = () => {
    return token ? token : localStorage.getItem("token");
  };

  const getStoreCode = () => {
    return storeCode ? storeCode : localStorage.getItem("storeCode");
  };

  const getRoles = () => {
    return localStorage.getItem("roles");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        getToken: getToken,
        getStoreCode: getStoreCode,
        getRoles: getRoles,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
