import { useEffect, useContext, useCallback, useState } from "react";
import { Grid, Label, Input } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import { getProductsApi } from "../../api/api";
import Utils from "../../utils/Utils";

const ProductModalSection = (props) => {
  const authCtx = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [productsOrdered, setProductsOrdered] = useState(
    props.productsOrdered ? props.productsOrdered : []
  );
  const [totalProductCost, setTotalProductCost] = useState(
    props.totalProductCost ? props.totalProductCost : 0
  );

  // Function to handle quantity change for a product
  const handleChange = (product, newQuantity) => {
    const updatedProductsOrdered = { ...productsOrdered };
    updatedProductsOrdered[product.id] = parseInt(newQuantity);
    setProductsOrdered(updatedProductsOrdered);

    let totalCost = calculateTotalCost(updatedProductsOrdered, products);
    totalCost = totalCost ? totalCost : 0;
    setTotalProductCost(totalCost);

    props.updateProductDetails(updatedProductsOrdered, totalCost);
  };

  function calculateTotalCost(updatedProducts, products) {
    let totalCost = 0;

    // Loop through updatedProducts
    for (let productId in updatedProducts) {
      if (updatedProducts.hasOwnProperty(productId)) {
        // Find corresponding product in products array
        const product = products.find((p) => p.id === productId);

        if (product) {
          // Calculate partial cost for this product
          const quantity = updatedProducts[productId];
          const partialCost = quantity * product.price;

          // Accumulate to total cost
          totalCost += partialCost;
        }
      }
    }

    return totalCost;
  }

  // Function to fetch products from API
  const fetchProducts = useCallback(async () => {
    try {
      const response = await getProductsApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedProducts = [];
      for (const key in response) {
        loadedProducts.push(Utils.toProduct(response[key]));
      }
      setProducts(loadedProducts);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  // Fetch products when component mounts or when authCtx changes
  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchProducts();
    }
  }, [fetchProducts, authCtx]);

  return (
    <Grid.Column width={5}>
      <fieldset className="fieldset">
        <legend>Products Ordered</legend>
        <Grid columns={2}>
          {props.productTypes &&
            props.productTypes.map((productType) => {
              const filteredProducts = products.filter(
                (product) => product.type === productType
              );
              if (filteredProducts.length > 0) {
                return (
                  <fieldset
                    className="fieldsetBorderStyleNone marginBottom10 marginTop10"
                    key={productType}
                  >
                    <legend>{productType}</legend>
                    {filteredProducts.map((product) => (
                      <Grid
                        columns={3}
                        verticalAlign="middle"
                        key={product.name}
                      >
                        <Grid.Column width={6}>
                          <Label basic className="borderNone paddingRight0">
                            <div>{product.name}</div>
                            <div>
                              <sub>{`PHP ${product.price}`}</sub>
                            </div>
                          </Label>
                        </Grid.Column>
                        <Grid.Column width={5} className="paddingBottom0">
                          <Input
                            min="0"
                            type="number"
                            value={productsOrdered[product.id] || 0}
                            onChange={(e) => {
                              handleChange(product, e.target.value);
                            }}
                            disabled={
                              props.selectedEvent.status !== "CHECKED_IN"
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <span style={{ fontWeight: "bolder" }}>
                            {`PHP ${
                              (productsOrdered[product.id] || 0) * product.price
                            }`}
                          </span>
                        </Grid.Column>
                      </Grid>
                    ))}
                  </fieldset>
                );
              }
              return null;
            })}
          <Grid.Column width={5}>
            <Label basic className="borderNone paddingRight0">
              <div>TOTAL</div>
              <sub>Payment is before COMPLETED status</sub>
            </Label>
          </Grid.Column>
          <Grid.Column width={11}>
            <span className="price">{`PHP ${totalProductCost} ${
              props.selectedEvent.productPaymentMOP && totalProductCost !== 0
                ? `(${props.selectedEvent.productPaymentMOP})`
                : ""
            }`}</span>
          </Grid.Column>
        </Grid>
      </fieldset>
    </Grid.Column>
  );
};

export default ProductModalSection;
