import React, { Fragment, useState } from "react";
import { Table } from "semantic-ui-react";
import ProductsModal from "./ProductsModal";
import Utils from "../../utils/Utils";

const ProductsTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const onClick = (promo) => {
    setIsModalOpen(true);
    setSelectedProduct(promo);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <div style={{ maxHeight: "800px", overflow: "auto" }}>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Price</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!props.products || props.products.length === 0 ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={3}>
                  No products created.
                </Table.Cell>
              </Table.Row>
            ) : (
              props.products.map((product) => {
                return (
                  <Table.Row key={product.id}>
                    <Table.Cell
                      className="pointer-on-hover"
                      onClick={() => onClick(product)}
                      textAlign="center"
                    >
                      {product.name}
                    </Table.Cell>
                    <Table.Cell
                      className={product.available ? "green" : "red"}
                      textAlign="center"
                    >
                      {product.available ? "Yes" : "No"}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{`PHP ${product.price}`}</Table.Cell>
                    <Table.Cell textAlign="center">{product.type}</Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {isModalOpen && (
        <ProductsModal
          productTypes={props.productTypes}
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedProduct={selectedProduct}
          eventName={Utils.EVENT_UPDATE}
        />
      )}
    </Fragment>
  );
};

export default ProductsTable;
