import { Fragment } from "react";
import { Modal } from "semantic-ui-react";

const NoLockerFound = (props) => {
  return (
    <Fragment>
      <Modal
        closeIcon
        open={props.isModalOpen}
        onClose={props.handleModalClose}
        size="tiny"
      >
        <Modal.Header>
          Search result for locker "{props.lockerName}"
        </Modal.Header>
        <Modal.Content>
          No active appointment found today given locker name.
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default NoLockerFound;
