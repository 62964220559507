import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import DashboardLayout from "./layouts/DashboardLayout";
import RootLayout from "./layouts/RootLayout";
import Dashboard from "./pages/Dashboard";
import Landing from "./pages/Landing";
import Reports from "./pages/Reports";
import NonMassage from "./pages/NonMassage";
import Customers from "./pages/Customers";
import Services from "./pages/Services";
import Promos from "./pages/Promos";
import Staffs from "./pages/Staffs";
import Rooms from "./pages/Rooms";
import Store from "./pages/Store";
import Lockers from "./pages/Lockers";
import Admin from "./pages/Admin";
import Products from "./pages/Products";
import Logout from "./components/logout/Logout";
import AuthContext from "./store/auth-context";
import {
  getAppointmentConfigApi,
  getAvailableResourcesApi,
  getUnavailableResourcesApi,
} from "./api/api";

function App() {
  const authCtx = useContext(AuthContext);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [modeOfPayments, setModeOfPayments] = useState([]);
  const [appointmentStartTimes, setAppointmentStartTimes] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [additionalTowelPayment, setAdditionalTowelPayment] = useState(null);
  const [staffScheduleTypes, setStaffScheduleTypes] = useState([]);
  const [staffBreaks, setStaffBreaks] = useState([]);
  const [serviceOfferedTypes, setServiceOfferedTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [customerStatuses, setCustomerStatuses] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [availableTherapists, setAvailableTherapists] = useState([]);
  const [availableLockers, setAvailableLockers] = useState([]);

  const [onBreak1, setOnBreak1] = useState([]);
  const [onBreak2, setOnBreak2] = useState([]);
  const [onDayOff, setOnDayOff] = useState([]);

  const fetchAppointmentConfig = useCallback(async () => {
    try {
      const response = await getAppointmentConfigApi(
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      setAppointmentTypes(response["types"]);
      setAppointmentStatuses(response["statuses"]);
      setAppointmentStartTimes(response["startTimes"]);
      setModeOfPayments(response["modeOfPayments"]);
      setDaysOfWeek(response["daysOfWeek"]);
      setAdditionalTowelPayment(response["additionalTowelPayment"]);
      setStaffScheduleTypes(response["staffScheduleTypes"]);
      setServiceOfferedTypes(response["serviceOfferedTypes"]);
      setProductTypes(response["productTypes"]);
      setCustomerStatuses(response["customerStatuses"]);
      setCustomerTypes(response["customerTypes"]);

      let loadedStaffBreaks = [];
      for (const obj of response["staffBreaks"]) {
        loadedStaffBreaks.push({
          id: obj.id,
          display: obj.display,
          start: obj.start,
          end: obj.end,
        });
      }
      setStaffBreaks(loadedStaffBreaks);
    } catch (error) {
      console.log("error: ", error);
    }
  }, [authCtx]);

  const fetchAvailableResources = useCallback(
    async (payload) => {
      try {
        const response = await getAvailableResourcesApi(
          payload.startTime,
          payload.startDate,
          payload.appointmentId,
          payload.serviceId,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );
        const { rooms, lockers, staffs } = response;
        setAvailableRooms(rooms);
        setAvailableLockers(lockers);
        setAvailableTherapists(staffs);
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [authCtx]
  );

  const fetchUnavailableResources = useCallback(
    async (payload) => {
      try {
        const response = await getUnavailableResourcesApi(
          payload.startTime,
          payload.startDate,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );
        const { onBreak1, onBreak2, onDayOff } = response;
        setOnBreak1(onBreak1);
        setOnBreak2(onBreak2);
        setOnDayOff(onDayOff);
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [authCtx]
  );

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchAppointmentConfig();
    }
  }, [fetchAppointmentConfig, authCtx]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />} errorElement={<Landing />}>
        <Route index element={<Landing />} />
        <Route path="/" element={<DashboardLayout />}>
          <Route
            path="dashboard"
            element={
              <Dashboard
                fetchAvailableResources={fetchAvailableResources}
                fetchUnavailableResources={fetchUnavailableResources}
                availableRooms={availableRooms}
                availableLockers={availableLockers}
                availableTherapists={availableTherapists}
                appointmentTypes={appointmentTypes}
                appointmentStatuses={appointmentStatuses}
                modeOfPayments={modeOfPayments}
                appointmentStartTimes={appointmentStartTimes}
                daysOfWeek={daysOfWeek}
                additionalTowelPayment={additionalTowelPayment}
                staffBreaks={staffBreaks}
                onBreak1={onBreak1}
                onBreak2={onBreak2}
                onDayOff={onDayOff}
                productTypes={productTypes}
              />
            }
          />
          <Route
            path="non-massage"
            element={
              <NonMassage
                fetchAvailableResources={fetchAvailableResources}
                availableRooms={availableRooms}
                availableLockers={availableLockers}
                availableTherapists={availableTherapists}
                appointmentTypes={appointmentTypes}
                appointmentStatuses={appointmentStatuses}
                modeOfPayments={modeOfPayments}
                appointmentStartTimes={appointmentStartTimes}
                additionalTowelPayment={additionalTowelPayment}
                productTypes={productTypes}
              />
            }
          />
          <Route
            path="products"
            element={<Products productTypes={productTypes} />}
          />
          <Route
            path="customers"
            element={
              <Customers
                customerStatuses={customerStatuses}
                customerTypes={customerTypes}
              />
            }
          />
          <Route path="reports" element={<Reports />} />
          <Route
            path="services"
            element={<Services serviceOfferedTypes={serviceOfferedTypes} />}
          />
          <Route path="promos" element={<Promos />} />
          <Route
            path="staffs"
            element={
              <Staffs
                appointmentStartTimes={appointmentStartTimes}
                staffScheduleTypes={staffScheduleTypes}
                staffBreaks={staffBreaks}
              />
            }
          />
          <Route path="rooms" element={<Rooms />} />
          <Route path="lockers" element={<Lockers />} />
          {authCtx.getRoles() && authCtx.getRoles().includes("SUPER_ADMIN") && (
            <Fragment>
              <Route path="admins" element={<Admin />} />
              <Route path="store" element={<Store />} />
            </Fragment>
          )}
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<Landing />} />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
