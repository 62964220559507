import React, { Fragment, useState } from "react";
import { Table } from "semantic-ui-react";
import RoomModal from "./RoomModal";
import Utils from "../../utils/Utils";

const StaffsTable = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState([]);

  const onClick = (room) => {
    setIsModalOpen(true);
    setSelectedRoom(room);
  };

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  return (
    <Fragment>
      <div style={{ maxHeight: "800px", overflow: "auto" }}>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Available</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Services</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!props.rooms || props.rooms.length === 0 ? (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan={3}>
                  No rooms created.
                </Table.Cell>
              </Table.Row>
            ) : (
              props.rooms.map((room) => {
                return (
                  <Table.Row key={room.id}>
                    <Table.Cell
                      className="pointer-on-hover"
                      onClick={() => onClick(room)}
                      textAlign="center"
                    >
                      {room.name}
                    </Table.Cell>
                    <Table.Cell
                      className={room.available ? "green" : "red"}
                      textAlign="center"
                    >
                      {room.available ? "Yes" : "No"}
                    </Table.Cell>
                    <Table.Cell>
                      <ul>
                        {room.serviceIds && room.serviceIds.length > 0
                          ? props.services
                              .filter((service) =>
                                room.serviceIds.includes(service.id)
                              )
                              .map((service) => (
                                <li key={service.id}>{service.name}</li>
                              ))
                          : "-"}
                      </ul>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </div>
      {isModalOpen && (
        <RoomModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          selectedRoom={selectedRoom}
          services={props.services}
          eventName={Utils.EVENT_UPDATE}
        />
      )}
    </Fragment>
  );
};

export default StaffsTable;
