import { Fragment, useCallback, useContext, useState, useEffect } from "react";
import { Grid, Label, Divider, Input, Button, Icon } from "semantic-ui-react";
import SuccessMessage from "../components/common/SuccessMessage";
import ErrorMessage from "../components/common/ErrorMessage";
import AuthContext from "../store/auth-context";
import Utils from "../utils/Utils";
import { getStoreApi, updateStoreLoyaltyPointsConfig } from "../api/api";

const Admin = () => {
  const authCtx = useContext(AuthContext);
  const [store, setStore] = useState([]);

  const [loyaltyMinimumPointsToRedeem, setLoyaltyMinimumPointsToRedeem] =
    useState("");
  const [
    loyaltyPointsToRedeemDivisibleBy,
    setLoyaltyPointsToRedeemDivisibleBy,
  ] = useState("");

  const [monLoyaltySpendAmount, setMonLoyaltySpendAmount] = useState("");
  const [monLoyaltySpendAmountEquivalent, setMonLoyaltySpendAmountEquivalent] =
    useState("");
  const [tueLoyaltySpendAmount, setTueLoyaltySpendAmount] = useState("");
  const [tueLoyaltySpendAmountEquivalent, setTueLoyaltySpendAmountEquivalent] =
    useState("");
  const [wedLoyaltySpendAmount, setWedLoyaltySpendAmount] = useState("");
  const [wedLoyaltySpendAmountEquivalent, setWedLoyaltySpendAmountEquivalent] =
    useState("");
  const [thuLoyaltySpendAmount, setThuLoyaltySpendAmount] = useState("");
  const [thuLoyaltySpendAmountEquivalent, setThuLoyaltySpendAmountEquivalent] =
    useState("");
  const [friLoyaltySpendAmount, setFriLoyaltySpendAmount] = useState("");
  const [friLoyaltySpendAmountEquivalent, setFriLoyaltySpendAmountEquivalent] =
    useState("");
  const [satLoyaltySpendAmount, setSatLoyaltySpendAmount] = useState("");
  const [satLoyaltySpendAmountEquivalent, setSatLoyaltySpendAmountEquivalent] =
    useState("");
  const [sunLoyaltySpendAmount, setSunLoyaltySpendAmount] = useState("");
  const [sunLoyaltySpendAmountEquivalent, setSunLoyaltySpendAmountEquivalent] =
    useState("");

  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleUpdateLoyaltyConfig = (event) => {
    event.preventDefault();

    if (
      !monLoyaltySpendAmount ||
      monLoyaltySpendAmount <= 0 ||
      !tueLoyaltySpendAmount ||
      tueLoyaltySpendAmount <= 0 ||
      !wedLoyaltySpendAmount ||
      wedLoyaltySpendAmount <= 0 ||
      !thuLoyaltySpendAmount ||
      thuLoyaltySpendAmount <= 0 ||
      !friLoyaltySpendAmount ||
      friLoyaltySpendAmount <= 0 ||
      !satLoyaltySpendAmount ||
      satLoyaltySpendAmount <= 0 ||
      !sunLoyaltySpendAmount ||
      sunLoyaltySpendAmount <= 0
    ) {
      handleFormError("Spend Amount is required and should be greater than 0");
      return;
    }

    if (
      !monLoyaltySpendAmountEquivalent ||
      monLoyaltySpendAmountEquivalent <= 0 ||
      !tueLoyaltySpendAmountEquivalent ||
      tueLoyaltySpendAmountEquivalent <= 0 ||
      !wedLoyaltySpendAmountEquivalent ||
      wedLoyaltySpendAmountEquivalent <= 0 ||
      !thuLoyaltySpendAmountEquivalent ||
      thuLoyaltySpendAmountEquivalent <= 0 ||
      !friLoyaltySpendAmountEquivalent ||
      friLoyaltySpendAmountEquivalent <= 0 ||
      !satLoyaltySpendAmountEquivalent ||
      satLoyaltySpendAmountEquivalent <= 0 ||
      !sunLoyaltySpendAmountEquivalent ||
      sunLoyaltySpendAmountEquivalent <= 0
    ) {
      handleFormError(
        "Spend Amount Equivalent is required and should be greater than 0"
      );
      return;
    }

    if (!loyaltyMinimumPointsToRedeem || loyaltyMinimumPointsToRedeem <= 0) {
      handleFormError(
        "Minimum Points To Redeem is required and should be greater than 0"
      );
      return;
    }

    if (
      !loyaltyPointsToRedeemDivisibleBy ||
      loyaltyPointsToRedeemDivisibleBy <= 0
    ) {
      handleFormError(
        "Points To Redeem Divisible By is required and should be greater than 0"
      );
      return;
    }

    const updateData = {
      loyaltyMinimumPointsToRedeem: loyaltyMinimumPointsToRedeem,
      loyaltyPointsToRedeemDivisibleBy: loyaltyPointsToRedeemDivisibleBy,
      loyaltyPointsConfig: {
        MON: {
          loyaltySpendAmount: monLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: monLoyaltySpendAmountEquivalent,
        },
        TUE: {
          loyaltySpendAmount: tueLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: tueLoyaltySpendAmountEquivalent,
        },
        WED: {
          loyaltySpendAmount: wedLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: wedLoyaltySpendAmountEquivalent,
        },
        THU: {
          loyaltySpendAmount: thuLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: thuLoyaltySpendAmountEquivalent,
        },
        FRI: {
          loyaltySpendAmount: friLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: friLoyaltySpendAmountEquivalent,
        },
        SAT: {
          loyaltySpendAmount: satLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: satLoyaltySpendAmountEquivalent,
        },
        SUN: {
          loyaltySpendAmount: sunLoyaltySpendAmount,
          loyaltySpendAmountEquivalent: sunLoyaltySpendAmountEquivalent,
        },
      },
    };

    updateStoreLoyaltyPointsConfig(
      JSON.stringify(updateData),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        handleFormSuccess("Store Loyaly config updated");
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError(error.message);
      });
  };

  const fetchStore = useCallback(async () => {
    try {
      const response = await getStoreApi(
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      let storeData = Utils.toStore(response);
      setStore(storeData);
      setLoyaltyMinimumPointsToRedeem(storeData.loyaltyMinimumPointsToRedeem);
      setLoyaltyPointsToRedeemDivisibleBy(
        storeData.loyaltyPointsToRedeemDivisibleBy
      );

      setMonLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.MON.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.MON.loyaltySpendAmount
          : ""
      );
      setMonLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.MON.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.MON.loyaltySpendAmountEquivalent
          : ""
      );

      setTueLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.TUE.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.TUE.loyaltySpendAmount
          : ""
      );
      setTueLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.TUE.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.TUE.loyaltySpendAmountEquivalent
          : ""
      );

      setWedLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.WED.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.WED.loyaltySpendAmount
          : ""
      );
      setWedLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.WED.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.WED.loyaltySpendAmountEquivalent
          : ""
      );

      setThuLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.THU.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.THU.loyaltySpendAmount
          : ""
      );
      setThuLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.THU.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.THU.loyaltySpendAmountEquivalent
          : ""
      );

      setFriLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.FRI.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.FRI.loyaltySpendAmount
          : ""
      );
      setFriLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.FRI.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.FRI.loyaltySpendAmountEquivalent
          : ""
      );

      setSatLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.SAT.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.SAT.loyaltySpendAmount
          : ""
      );
      setSatLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.SAT.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.SAT.loyaltySpendAmountEquivalent
          : ""
      );

      setSunLoyaltySpendAmount(
        storeData.loyaltyPointsConfig.SUN.loyaltySpendAmount
          ? storeData.loyaltyPointsConfig.SUN.loyaltySpendAmount
          : ""
      );
      setSunLoyaltySpendAmountEquivalent(
        storeData.loyaltyPointsConfig.SUN.loyaltySpendAmountEquivalent
          ? storeData.loyaltyPointsConfig.SUN.loyaltySpendAmountEquivalent
          : ""
      );
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchStore();
    }
  }, [fetchStore, authCtx]);

  return (
    <Fragment>
      <Grid columns={2}>
        <Grid.Column width={4}></Grid.Column>
        <Grid.Column width={8}>
          <fieldset className="fieldset marginTop20">
            <legend>Store Details</legend>
            <Grid columns={2} className="marginTop10">
              <Grid.Column width={4}>
                <Label basic className="borderNone paddingRight0">
                  Name
                </Label>
              </Grid.Column>
              <Grid.Column width={12}>{store.name}</Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </fieldset>

          <fieldset className="fieldset marginTop20">
            <legend>Loyalty Details</legend>

            {modalFormErrorMessage && (
              <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
            )}

            {modalFormSuccessMessage && (
              <SuccessMessage
                successMessage={modalFormSuccessMessage}
              ></SuccessMessage>
            )}

            <Grid.Column width={4}></Grid.Column>
            <fieldset className="fieldset">
              <legend>Monday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={monLoyaltySpendAmount}
                    name="monLoyaltySpendAmount"
                    onChange={(e) => setMonLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={monLoyaltySpendAmountEquivalent}
                    name="monLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setMonLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Tuesday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={tueLoyaltySpendAmount}
                    name="tueLoyaltySpendAmount"
                    onChange={(e) => setTueLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={tueLoyaltySpendAmountEquivalent}
                    name="tueLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setTueLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Wednesday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={wedLoyaltySpendAmount}
                    name="wedLoyaltySpendAmount"
                    onChange={(e) => setWedLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={wedLoyaltySpendAmountEquivalent}
                    name="wedLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setWedLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Thursday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={thuLoyaltySpendAmount}
                    name="thuLoyaltySpendAmount"
                    onChange={(e) => setThuLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={thuLoyaltySpendAmountEquivalent}
                    name="thuLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setThuLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Friday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={friLoyaltySpendAmount}
                    name="friLoyaltySpendAmount"
                    onChange={(e) => setFriLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={friLoyaltySpendAmountEquivalent}
                    name="friLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setFriLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Saturday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={satLoyaltySpendAmount}
                    name="satLoyaltySpendAmount"
                    onChange={(e) => setSatLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={satLoyaltySpendAmountEquivalent}
                    name="satLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setSatLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <fieldset className="fieldset">
              <legend>Sunday</legend>
              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={sunLoyaltySpendAmount}
                    name="sunLoyaltySpendAmount"
                    onChange={(e) => setSunLoyaltySpendAmount(e.target.value)}
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />

              <Grid columns={2} className="marginTop10">
                <Grid.Column width={6}>
                  <Label
                    basic
                    className="borderNone paddingRight0 paddingTop10"
                  >
                    Spend Amount Equivalent
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Input
                    type="number"
                    value={sunLoyaltySpendAmountEquivalent}
                    name="sunLoyaltySpendAmountEquivalent"
                    onChange={(e) =>
                      setSunLoyaltySpendAmountEquivalent(e.target.value)
                    }
                  ></Input>
                </Grid.Column>
              </Grid>
              <Divider hidden fitted />
            </fieldset>

            <Grid columns={2} className="marginTop10">
              <Grid.Column width={6}>
                <Label basic className="borderNone paddingRight0 paddingTop10">
                  Minimum Points to Redeem (Mon - Sun)
                </Label>
              </Grid.Column>
              <Grid.Column width={10}>
                <Input
                  type="number"
                  value={loyaltyMinimumPointsToRedeem}
                  onChange={(e) =>
                    setLoyaltyMinimumPointsToRedeem(e.target.value)
                  }
                  name="loyaltyMinimumPointsToRedeem"
                ></Input>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />

            <Grid columns={2} className="marginTop10">
              <Grid.Column width={6}>
                <Label basic className="borderNone paddingRight0 paddingTop10">
                  Points to Redeem Divisible By (Mon - Sun)
                </Label>
              </Grid.Column>
              <Grid.Column width={10}>
                <Input
                  type="number"
                  value={loyaltyPointsToRedeemDivisibleBy}
                  onChange={(e) =>
                    setLoyaltyPointsToRedeemDivisibleBy(e.target.value)
                  }
                  name="loyaltyPointsToRedeemDivisibleBy"
                ></Input>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />

            <div
              style={{ textAlign: "right" }}
              className="marginTop20 marginRight20 marginBottom20"
            >
              <Button color="blue" onClick={handleUpdateLoyaltyConfig}>
                <Icon name="pencil" /> UPDATE
              </Button>
            </div>
          </fieldset>

          <div style={{ textAlign: "right" }} className="marginTop20">
            <div style={{ fontSize: "10px" }}>
              {`Created by ${store.createdBy} on ${store.createdDateTime}`}
            </div>
            <div style={{ fontSize: "10px" }}>
              {`Last updated by ${store.lastUpdatedBy} on ${store.updatedDateTime}`}
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={4}></Grid.Column>
      </Grid>
    </Fragment>
  );
};
export default Admin;
