import React, {
  Fragment,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { Button, Icon, Grid } from "semantic-ui-react";
import StaffsTable from "../components/staffs/StaffsTable";
import AuthContext from "../store/auth-context";
import Utils from "../utils/Utils";
import StaffModal from "../components/staffs/StaffModal";
import { getStaffsApi, getServicesApi } from "../api/api";

const Staffs = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allTherapists, setAllTherapists] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const fetchTherapists = useCallback(async () => {
    try {
      const response = await getStaffsApi(
        false,
        null,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedTherapists = [];
      for (const key in response) {
        loadedTherapists.push(Utils.toStaff(response[key]));
      }
      setAllTherapists(loadedTherapists);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push(Utils.toService(response[key]));
      }
      setAllServices(loadedServices);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchTherapists();
      fetchServices();
    }
  }, [fetchTherapists, fetchServices, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <StaffsTable
            therapists={allTherapists}
            services={allServices}
            daysOfWeek={props.daysOfWeek}
            appointmentStartTimes={props.appointmentStartTimes}
            staffScheduleTypes={props.staffScheduleTypes}
            staffBreaks={props.staffBreaks}
          ></StaffsTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <StaffModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          staffScheduleTypes={props.staffScheduleTypes}
          services={allServices}
          staffBreaks={props.staffBreaks}
          eventName={Utils.EVENT_ADD}
        />
      )}
    </Fragment>
  );
};

export default Staffs;
