import { Fragment } from "react";
import { Grid, Label, Divider } from "semantic-ui-react";

const CustomerStats = (props) => {
  return (
    <Fragment>
      <Grid columns={6}>
        <Grid.Column>
          <Label
            basic
            htmlFor="customerPointsEarned"
            className="borderNone paddingRight0"
          >
            Loyalty Points
          </Label>
        </Grid.Column>
        <Grid.Column className="marginTop10">
          {props.customerPoints ? props.customerPoints : 0}
        </Grid.Column>

        <Grid.Column>
          <Label
            basic
            htmlFor="customerVisitCount"
            className="borderNone paddingRight0"
          >
            Visit Count
          </Label>
        </Grid.Column>
        <Grid.Column className="marginTop10">
          {props.customerVisitCount ? props.customerVisitCount : 0}
        </Grid.Column>

        <Grid.Column>
          <Label
            basic
            htmlFor="customerCancelCount"
            className="borderNone paddingRight0"
          >
            Cancel Count
          </Label>
        </Grid.Column>
        <Grid.Column className="marginTop10">
          {props.customerCancelCount ? props.customerCancelCount : 0}
        </Grid.Column>
      </Grid>
      <Divider hidden fitted />
    </Fragment>
  );
};

export default CustomerStats;
