import React, { useState } from "react";
import { Divider, Grid } from "semantic-ui-react";
import { Outlet } from "react-router-dom";
import NavBar from "../components/common/NavBar";
import LoaderComponent from "../components/common/LoaderComponent";

export default function DashboardLayout() {
  const [showLoader, setShowLoader] = useState(false);
  const setLoader = (showLoader) => {
    setShowLoader(setLoader);
  };
  return (
    <React.Fragment>
      <Grid>
        <Grid.Row className="paddingBottom0">
          <Grid.Column width={16}>
            <NavBar />
            <Divider hidden fitted />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="paddingTop0">
          <Grid.Column width={16}>
            <Outlet setLoader={setLoader} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {showLoader ? <LoaderComponent /> : null}
    </React.Fragment>
  );
}
