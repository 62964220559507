import React, { Fragment, useContext, useState } from "react";
import { Grid, Input, Button, Label, Form, Icon } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import ErrorMessage from "../common/ErrorMessage";

const CommissionReportForm = () => {
  const authCtx = useContext(AuthContext);
  const [start, setStart] = useState(Utils.getDateToday());
  const [disableButton, setDisableButton] = useState(false);
  const [end, setEnd] = useState(Utils.getDateToday());
  const [errorMessage, setErrorMessage] = useState("");

  const submitBookingHandler = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setDisableButton(true);

    if (start > end) {
      handleFormError("End Date should be greater than Start Date");
      setDisableButton(false);
      return;
    }

    const response = await fetch(
      `${
        process.env.REACT_APP_BACKEND_API
      }/v1/reports/commissions/date-range/download?start=${start}&end=${end}&storeCode=${authCtx.getStoreCode()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          STORE_CODE: authCtx.getStoreCode(),
          Authorization: `Bearer ${authCtx.getToken()}`,
        },
      }
    );

    if (response.ok) {
      let storeCode = authCtx.getStoreCode().replaceAll("_", "");
      const filename = `TherapistCommissions_${start}-to-${end}_${storeCode}.xlsx`;

      setDisableButton(false);

      return response.blob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } else {
      handleFormError("Report generation failed");
    }

    setDisableButton(false);
  };

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setErrorMessage(errorMessage);
  };

  return (
    <Fragment>
      <Form onSubmit={submitBookingHandler}>
        <Grid>
          {errorMessage && (
            <Grid.Row>
              <Grid.Column width="5">
                <ErrorMessage errorMessage={errorMessage}></ErrorMessage>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width="3">
              <Label basic className="borderNone">
                Start:
              </Label>
              <Input
                name="start"
                type="date"
                value={start}
                onChange={(e) => setStart(e.target.value)}
              ></Input>
            </Grid.Column>
            <Grid.Column width="3">
              <Label basic className="borderNone">
                End:
              </Label>
              <Input
                name="end"
                type="date"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
              ></Input>
            </Grid.Column>
            <Grid.Column width="10">
              <Button disabled={disableButton} primary>
                <Icon name="download" />
                DOWNLOAD
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Fragment>
  );
};

export default CommissionReportForm;
