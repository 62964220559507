import { Fragment, useState, useContext } from "react";
import AppointmentModal from "../appointment/AppointmentModal";
import NoLockerFound from "../appointment/NoLockerFound";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import { getAppointmentBasedOnLockerInUseNow } from "../../api/api";

const SearchActiveLocker = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveLockerFound, setIsActiveLockerFound] = useState(false);
  const [lockerName, setLockerName] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const requestData = Object.fromEntries(formData.entries());
    await getAppointmentBasedOnLockerInUseNow(
      requestData.locker,
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then((response) => {
        setSelectedEvent(Utils.toAppointment(response));
        setIsActiveLockerFound(true);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsActiveLockerFound(false);
      });
    setLockerName(requestData.locker);
    setIsModalOpen(true);
  };

  const handleModalClose = (needsReload) => {
    setIsModalOpen(false);
    setIsActiveLockerFound(false);
    setLockerName("");
    props.handleOnCloseModal(needsReload);
  };

  return (
    <Fragment>
      <form onSubmit={onSubmitHandler}>
        <div className="ui aligned category search item">
          <div className="ui icon input" style={{ position: "relative" }}>
            <input
              name="locker"
              className="prompt"
              type="text"
              placeholder="Search in use locker today"
              style={{ width: "220px" }}
            />
            <button
              type="submit"
              className="ui icon button"
              style={{
                position: "absolute",
                right: 0,
                backgroundColor: "transparent",
              }}
            >
              <i className="search link icon" />
            </button>
          </div>
          {isActiveLockerFound ? (
            <AppointmentModal
              store={props.store}
              fetchAvailableResources={props.fetchAvailableResources}
              handleModalClose={handleModalClose}
              isModalOpen={isModalOpen}
              selectedEvent={selectedEvent}
              services={props.services}
              availableServices={props.availableServices}
              availablePromos={props.availablePromos}
              allLockers={props.allLockers}
              availableLockers={props.availableLockers}
              allRooms={props.allRooms}
              availableRooms={props.availableRooms}
              allTherapists={props.allTherapists}
              availableTherapists={props.availableTherapists}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              additionalTowelPayment={props.additionalTowelPayment}
              isMassageAppointment={props.isMassageAppointment}
              productTypes={props.productTypes}
            />
          ) : (
            <NoLockerFound
              handleModalClose={handleModalClose}
              isModalOpen={isModalOpen}
              lockerName={lockerName}
            />
          )}
        </div>
      </form>
    </Fragment>
  );
};

export default SearchActiveLocker;
