import { Fragment, useContext, useState, useEffect, useCallback } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import Utils from "../utils/Utils";
import ProductsTable from "../components/products/ProductsTable";
import ProductsModal from "../components/products/ProductsModal";
import AuthContext from "../store/auth-context";
import { getProductsApi } from "../api/api";

const Products = (props) => {
  const authCtx = useContext(AuthContext);
  const [allProducts, setAllProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchProducts = useCallback(async () => {
    try {
      const response = await getProductsApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedProducts = [];
      for (const key in response) {
        loadedProducts.push(Utils.toProduct(response[key]));
      }
      setAllProducts(loadedProducts);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchProducts();
    }
  }, [fetchProducts, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <ProductsTable
            productTypes={props.productTypes}
            products={allProducts}
          ></ProductsTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <ProductsModal
          productTypes={props.productTypes}
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD}
        />
      )}
    </Fragment>
  );
};

export default Products;
