import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";

function NavBar() {
  const [activeItem, setActiveItem] = useState("Appointments");
  const authCtx = useContext(AuthContext);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  let storeCode = authCtx.getStoreCode();

  return (
    <Segment inverted={true} className="borderRadius0">
      <Menu inverted={true} pointing secondary>
        <Menu.Item name={storeCode.replace("_", " ")} />

        <Menu.Item
          as={NavLink}
          to="/dashboard"
          name="Massage Appointments"
          active={activeItem === "Massage Appointments"}
          onClick={handleItemClick}
        />

        <Menu.Item
          as={NavLink}
          to="/non-massage"
          name="Other Appointments"
          active={activeItem === "Non-Massage Appointments"}
          onClick={handleItemClick}
        />

        <Menu.Item
          as={NavLink}
          to="/products"
          name="Products"
          active={activeItem === "Products"}
          onClick={handleItemClick}
        />

        <Menu.Item
          as={NavLink}
          to="/reports"
          name="Reports"
          active={activeItem === "Reports"}
          onClick={handleItemClick}
        />

        <Menu.Item
          to="/customers"
          as={NavLink}
          name="Customers"
          active={activeItem === "Customers"}
          onClick={handleItemClick}
        />

        <Menu.Item
          to="/services"
          as={NavLink}
          name="Services"
          active={activeItem === "Services"}
          onClick={handleItemClick}
        />

        <Menu.Item
          as={NavLink}
          to="/staffs"
          name="Therapists"
          active={activeItem === "Therapists"}
          onClick={handleItemClick}
        />

        <Menu.Item
          to="/rooms"
          as={NavLink}
          name="Rooms"
          active={activeItem === "Rooms"}
          onClick={handleItemClick}
        />

        <Menu.Item
          to="/lockers"
          as={NavLink}
          name="Lockers"
          active={activeItem === "Lockers"}
          onClick={handleItemClick}
        />

        <Menu.Item
          to="/promos"
          as={NavLink}
          name="Promos"
          active={activeItem === "Promos"}
          onClick={handleItemClick}
        />

        {authCtx.getRoles() && authCtx.getRoles().includes("SUPER_ADMIN") && (
          <Menu.Menu position="right">
            <Menu.Item
              to="/admins"
              as={NavLink}
              name="Admins"
              active={activeItem === "Admins"}
              onClick={handleItemClick}
            />
            <Menu.Item
              to="/store"
              as={NavLink}
              name="Store"
              active={activeItem === "Store"}
              onClick={handleItemClick}
            />
          </Menu.Menu>
        )}

        <Menu.Menu position="right">
          <Menu.Item
            as={NavLink}
            to="/logout"
            name="Logout"
            active={activeItem === "Logout"}
            onClick={handleItemClick}
          ></Menu.Item>
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}

export default NavBar;
