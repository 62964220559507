import { Fragment, useState, useContext, useEffect, useCallback } from "react";
import { Grid, Message } from "semantic-ui-react";
import Legend from "../components/common/Legend";
import SearchActiveLocker from "../components/appointment/SearchActiveLocker";
import AppointmentForm from "../components/appointment/AppointmentForm";
import AppointmentsTable from "../components/appointment/AppointmentsTable";
import Utils from "../utils/Utils";
import AuthContext from "../store/auth-context";
import {
  getStoreApi,
  getServicesApi,
  getLockersApi,
  getPromosApi,
  getAppointmentsByDateApi,
  getStaffsApi,
  getRoomsApi,
} from "../api/api";

const NonMassage = (props) => {
  const authCtx = useContext(AuthContext);
  const [store, setStore] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [services, setServices] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [availablePromos, setAvailablePromos] = useState([]);

  const [allLockers, setAllLockers] = useState([]);
  const [allTherapists, setAllTherapists] = useState([]);
  const [allRooms, setAllRooms] = useState([]);

  const [currentView, setCurrentView] = useState(Utils.getDateToday());

  const [formErrorMessage, setFormErrorMessage] = useState("");

  const handleOnCloseModal = (needsReload) => {
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push({
          id: response[key].id,
          name: response[key].name,
          price: response[key].price,
          duration: response[key].duration,
          breakDuration: response[key].breakDuration,
          type: response[key].type,
          available: response[key].available,
          hasStaff: response[key].hasStaff,
          hasRoom: response[key].hasRoom,
        });
      }
      setServices(loadedServices);
      setAvailableServices(
        loadedServices.filter((service) => service.type !== "MASSAGE")
      );
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchLockers = useCallback(async () => {
    try {
      const response = await getLockersApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedLockers = [];
      for (const key in response) {
        loadedLockers.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
        });
      }
      setAllLockers(loadedLockers);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchPromos = useCallback(async () => {
    try {
      const response = await getPromosApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      const loadedPromos = [];

      for (const key in response) {
        loadedPromos.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
          serviceLessPriceMap: response[key].serviceLessPriceMap,
          createdDateTime: response[key].createdDateTime,
          updatedDateTime: response[key].updatedDateTime,
        });
      }
      setAvailablePromos(loadedPromos.filter((promo) => promo.available));
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchTherapists = useCallback(async () => {
    try {
      const response = await getStaffsApi(
        true,
        null,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedTherapists = [];
      for (const key in response) {
        loadedTherapists.push({
          id: response[key].id,
          name: response[key].name.toUpperCase(),
          available: response[key].available,
          serviceCommissionMap: response[key].serviceCommissionMap,
          daysOff: response[key].daysOff,
          type: response[key].type,
          startDutyTime: response[key].startDutyTime,
          lastCallTime: response[key].lastCallTime,
          createdDateTime: response[key].createdDateTime,
          updatedDateTime: response[key].updatedDateTime,
        });
      }
      setAllTherapists(loadedTherapists);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await getRoomsApi(
        true,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      const loadedRooms = [];

      for (const key in response) {
        loadedRooms.push({
          id: response[key].id,
          name: response[key].name,
          available: response[key].available,
          serviceIds: response[key].serviceIds,
          type: response[key].type,
        });
      }
      setAllRooms(loadedRooms);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchStore = useCallback(async () => {
    try {
      const response = await getStoreApi(
        authCtx.getStoreCode(),
        authCtx.getToken()
      );
      let storeData = Utils.toStore(response);
      setStore(storeData);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchAppointmentsByDate = useCallback(
    async (date) => {
      try {
        const response = await getAppointmentsByDateApi(
          date,
          true,
          authCtx.getStoreCode(),
          authCtx.getToken()
        );
        const loadedAppointments = [];
        for (const key in response) {
          let appointmentResponse = response[key];
          let appointment = Utils.toAppointment(appointmentResponse);
          if (appointmentResponse.serviceOfferedType === "OTHERS") {
            loadedAppointments.push(appointment);
          }
        }
        setAppointments(loadedAppointments);
        setCurrentView(date);
      } catch (error) {
        console.log("error: " + error);
      }
    },
    [authCtx]
  );

  const handleFormError = (errorMessage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setFormErrorMessage(errorMessage);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchStore();
      fetchServices();
      fetchLockers();
      fetchPromos();
      fetchTherapists();
      fetchRooms();
      fetchAppointmentsByDate(Utils.getDateToday());
    }
  }, [
    authCtx,
    fetchStore,
    fetchServices,
    fetchLockers,
    fetchPromos,
    fetchTherapists,
    fetchRooms,
    fetchAppointmentsByDate,
  ]);

  return (
    <Fragment>
      <Grid>
        <Grid.Row className="paddingBottom0">
          <Grid.Column width={4} textAlign="center">
            <h3>Create Appointment Form</h3>
          </Grid.Column>
          <Grid.Column width={2} className="marginTop10">
            {Utils.convertToDescriptiveDate(currentView)}
          </Grid.Column>
          <Grid.Column width={7}>
            <Legend></Legend>
          </Grid.Column>
          <Grid.Column width={3}>
            <SearchActiveLocker
              store={store}
              fetchAvailableResources={props.fetchAvailableResources}
              services={services}
              availableServices={availableServices}
              availablePromos={availablePromos}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              availableTherapists={props.availableTherapists}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              handleFormError={handleFormError}
              isMassageAppointment={false}
              additionalTowelPayment={props.additionalTowelPayment}
              productTypes={props.productTypes}
              handleOnCloseModal={handleOnCloseModal}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="paddingTop0">
          <Grid.Column width={4}>
            {formErrorMessage && (
              <Message negative className="marginLeft10">
                <Message.Header>Error</Message.Header>
                <p>{formErrorMessage}</p>
              </Message>
            )}
            <AppointmentForm
              store={store}
              fetchAppointmentsByDate={fetchAppointmentsByDate}
              fetchAvailableResources={props.fetchAvailableResources}
              availableServices={availableServices}
              availablePromos={availablePromos}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              availableTherapists={props.availableTherapists}
              appointmentTypes={props.appointmentTypes}
              appointmentStatuses={props.appointmentStatuses}
              appointmentStartTimes={props.appointmentStartTimes}
              modeOfPayments={props.modeOfPayments}
              handleFormError={handleFormError}
              isMassageAppointment={false}
              additionalTowelPayment={props.additionalTowelPayment}
            ></AppointmentForm>
          </Grid.Column>
          <Grid.Column width={12}>
            <AppointmentsTable
              store={store}
              fetchAvailableResources={props.fetchAvailableResources}
              appointments={appointments}
              services={services}
              availableServices={availableServices}
              availablePromos={availablePromos}
              allLockers={allLockers}
              availableLockers={props.availableLockers}
              allRooms={allRooms}
              availableRooms={props.availableRooms}
              allTherapists={allTherapists}
              availableTherapists={props.availableTherapists}
              appointmentStatuses={props.appointmentStatuses}
              appointmentTypes={props.appointmentTypes}
              modeOfPayments={props.modeOfPayments}
              additionalTowelPayment={props.additionalTowelPayment}
              productTypes={props.productTypes}
              handleOnCloseModal={handleOnCloseModal}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default NonMassage;
