import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Header, Divider, Icon } from "semantic-ui-react";
import AuthContext from "../../store/auth-context";
import { authenticateApi } from "../../api/api";

const Login = (props) => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setErrorMessage("Please enter both username and password.");
      return;
    }
    try {
      const response = await authenticateApi(username, password);
      authCtx.onLogin(response.idToken, response.storeCode, response.roles);
      navigate("/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const togglePasswordVisibility = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form-container">
      <div className="login-form">
        <Form onSubmit={handleSubmit}>
          <Header as="h1" textAlign="center">
            Log in to your account
          </Header>
          <Divider hidden />
          <Form.Field>
            <label htmlFor="username">Username</label>
            <Input
              icon="user circle"
              iconPosition="left"
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                if (errorMessage !== "") {
                  setErrorMessage("");
                }
              }}
              required
              autoComplete="username"
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="password">Password</label>
            <Input
              icon="lock"
              iconPosition="left"
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (errorMessage !== "") {
                  setErrorMessage("");
                }
              }}
              required
              autoComplete="current-password"
              action={
                <Button
                  icon
                  onClick={togglePasswordVisibility}
                  disabled={!password}
                >
                  <Icon name={showPassword ? "eye slash" : "eye"} />
                </Button>
              }
            />
          </Form.Field>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <Divider hidden />
          <Button type="submit" primary fluid>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
