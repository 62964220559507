import { Fragment, useCallback, useContext, useState, useEffect } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import AuthContext from "../store/auth-context";
import Utils from "../utils/Utils";
import AdminsTable from "../components/admins/AdminsTable";
import AdminsModal from "../components/admins/AdminsModal";
import { getAdminsApi } from "../api/api";

const Admin = () => {
  const authCtx = useContext(AuthContext);
  const [admins, setAdmins] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchAdmins = useCallback(async () => {
    try {
      const response = await getAdminsApi(
        "ADMIN",
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedAdmins = [];
      for (const key in response) {
        loadedAdmins.push(Utils.toUsers(response[key]));
      }
      setAdmins(loadedAdmins);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchAdmins();
    }
  }, [fetchAdmins, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <AdminsTable
            admins={admins}
            isModalOpen={isModalOpen}
            handleOnCloseModal={handleOnCloseModal}
          ></AdminsTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && 
        <AdminsModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD} 
        />}
    </Fragment>
  );
};
export default Admin;
