import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import SearchCustomerForm from "../components/customers/SearchCustomerForm";

const Customers = (props) => {
  return (
    <Fragment>
      <Grid>
        <Grid.Column width={2}></Grid.Column>
        <Grid.Column width={12}>
          <SearchCustomerForm
            customerStatuses={props.customerStatuses}
            customerTypes={props.customerTypes}
          ></SearchCustomerForm>
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default Customers;
