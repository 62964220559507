import { Fragment, useState, useContext } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  Label,
  Input,
  Grid,
  Divider,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import AuditInfo from "../common/AuditInfo";
import SuccessMessage from "../common/SuccessMessage";
import UpdateReminder from "../common/UpdateReminder";
import Utils from "../../utils/Utils";
import { updateStaffApi, addStaffApi } from "../../api/api";

const StaffModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [staffName, setStaffName] = useState(
    props.eventName === Utils.EVENT_UPDATE ? props.selectedTherapist.name : ""
  );
  const [isStaffAvailable, setIsStaffAvailable] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedTherapist.available
      : true
  );
  const [selectedDaysOff, setSelectedDaysOff] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedTherapist.daysOff
      : []
  );

  const [commissionValues, setCommissionValues] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedTherapist.serviceCommissionMap
      : {}
  );
  const handleCommissionChange = (serviceId, newValue) => {
    if (newValue === "") {
      // If the new value is empty, remove the serviceId from commissionValues
      setCommissionValues((prevValues) => {
        const updatedValues = { ...prevValues };
        delete updatedValues[serviceId];
        return updatedValues;
      });
    } else {
      // If the new value is not empty, update the commission value
      setCommissionValues((prevValues) => ({
        ...prevValues,
        [serviceId]: newValue,
      }));
    }
  };

  const [staffBreaks, setStaffBreaks] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedTherapist.staffBreaks
      : ""
  );
  const [staffScheduleType, setStaffScheduleType] = useState(
    props.eventName === Utils.EVENT_UPDATE
      ? props.selectedTherapist.staffScheduleType
      : ""
  );

  const [hasUpdates, setHasUpdates] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);

  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");

  const handleDaySelect = (_, { value }) => {
    setSelectedDaysOff(value);
  };

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalStaffForm");
    if (form) {
      const formData = new FormData(form);

      const updateData = {};
      for (let [name, value] of formData.entries()) {
        updateData[name] = value;
      }

      if (!updateData.name) {
        handleFormError("Therapist Name is required and should be unique");
        return;
      }

      if (!updateData.staffScheduleType) {
        handleFormError("Therapist Schedule Type is required");
        return;
      }

      updateData["available"] = isStaffAvailable ? "true" : "false";

      updateData.storeCode = authCtx.getStoreCode();
      updateData.serviceCommissionMap = commissionValues;
      updateData.daysOff = selectedDaysOff;

      updateStaffApi(
        props.selectedTherapist.id,
        JSON.stringify(updateData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Therapist details updated");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  const handleAddButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalStaffForm");
    if (form) {
      const formData = new FormData(form);

      const addData = {};
      for (let [name, value] of formData.entries()) {
        addData[name] = value;
      }

      if (!addData.name) {
        handleFormError("Therapist Name is required and should be unique");
        return;
      }

      if (!addData.staffScheduleType) {
        handleFormError("Therapist Schedule Type is required");
        return;
      }

      addData["available"] = isStaffAvailable ? "true" : "false";

      addData.storeCode = authCtx.getStoreCode();
      addData.serviceCommissionMap = commissionValues;
      addData.daysOff = selectedDaysOff;

      addStaffApi(
        JSON.stringify(addData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Therapist added");
          setModalFormErrorMessage("");
          setHasUpdates(true);
          setDisableAddButton(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    } else {
      handleFormError("Unable to find form");
    }
  };

  return (
    <Fragment>
      <Modal
        closeIcon
        size="large"
        onClose={handleOnCloseModal}
        open={props.isModalOpen}
      >
        <Modal.Header>
          {props.eventName === Utils.EVENT_UPDATE && (
            <Fragment>
              Update Therapist Details: {props.selectedTherapist.name}
            </Fragment>
          )}
          {props.eventName === Utils.EVENT_ADD && (
            <Fragment>Add Therapist</Fragment>
          )}
        </Modal.Header>
        <Modal.Content>
          {modalFormErrorMessage && (
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          )}
          {modalFormSuccessMessage && (
            <SuccessMessage
              successMessage={modalFormSuccessMessage}
            ></SuccessMessage>
          )}
          <Form id="modalStaffForm">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={7}>
                  <fieldset className="fieldset marginLeft10">
                    <legend>Therapist Details</legend>
                    <Grid columns={2}>
                      <Grid.Column width={4}>
                        <Label
                          basic
                          htmlFor="modalStaffName"
                          className="borderNone paddingRight0"
                        >
                          Therapist Name
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Input
                          id="modalStaffName"
                          name="name"
                          value={staffName}
                          onChange={(e) => {
                            setStaffName(e.target.value);
                          }}
                          required
                        ></Input>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalIsAvailable"
                          className="borderNone paddingRight0"
                        >
                          Available
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Checkbox
                          toggle
                          id="modalIsAvailable"
                          name="available"
                          className="marginTop10"
                          checked={isStaffAvailable}
                          value={isStaffAvailable ? "true" : "false"}
                          onChange={(e) => {
                            setIsStaffAvailable(!isStaffAvailable);
                          }}
                        ></Checkbox>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalScheduleType"
                          className="borderNone paddingRight0"
                        >
                          Schedule Type
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <select
                          id="staffScheduleType"
                          name="staffScheduleType"
                          value={staffScheduleType}
                          onChange={(e) => setStaffScheduleType(e.target.value)}
                        >
                          {props.staffScheduleTypes.map((type) => (
                            <option key={type} value={type} id={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalBreakHours"
                          className="borderNone paddingRight0"
                        >
                          Break
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <select
                          id="modalBreakHours"
                          name="staffBreaks"
                          value={staffBreaks}
                          onChange={(e) => setStaffBreaks(e.target.value)}
                        >
                          <option value="" key="">
                            {Utils.BLANK}
                          </option>
                          {props.staffBreaks &&
                            props.staffBreaks.map((staffBreak) => {
                              return (
                                <option
                                  id={staffBreak.id}
                                  key={staffBreak.id}
                                  value={staffBreak.id}
                                >
                                  {staffBreak.display}
                                </option>
                              );
                            })}
                        </select>
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />

                    <Grid columns={2}>
                      <Grid.Column width={4} className="marginTop10">
                        <Label
                          basic
                          htmlFor="modalDayOff"
                          className="borderNone paddingRight0"
                        >
                          Days Off
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        <Dropdown
                          placeholder="Select therapist day off"
                          fluid
                          multiple
                          selection
                          options={Utils.getDaysOfWeekOptions()}
                          value={selectedDaysOff}
                          onChange={handleDaySelect}
                        />
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </fieldset>
                </Grid.Column>

                <Grid.Column width={9}>
                  <fieldset className="fieldset">
                    <legend>Service & Commission Mapping</legend>
                    <Grid columns={2}>
                      <Grid.Column width={4}>
                        <Label
                          basic
                          htmlFor="modalServiceCommissionMapping"
                          className="borderNone paddingRight0"
                        >
                          Service & Commission Mapping
                          <div>
                            <sub>
                              Leave commission empty if therapist is not
                              qualified to do service
                            </sub>
                          </div>
                        </Label>
                      </Grid.Column>
                      <Grid.Column width={12} className="paddingTop20">
                        {props.services &&
                          props.services.map((service) => (
                            <Fragment key={service.id}>
                              {service.type === "MASSAGE" && (
                                <div>
                                  <Label
                                    basic
                                    className="borderNone marginTop10"
                                  >
                                    {`${service.name}:`}
                                  </Label>
                                  <Input
                                    min="1"
                                    type="number"
                                    value={commissionValues[service.id] || ""}
                                    onChange={(e) =>
                                      handleCommissionChange(
                                        service.id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </Fragment>
                          ))}
                      </Grid.Column>
                    </Grid>
                    <Divider hidden fitted />
                  </fieldset>
                </Grid.Column>
              </Grid.Row>
              {props.eventName === Utils.EVENT_UPDATE && (
                <Grid.Row>
                  <Grid.Column>
                    <UpdateReminder></UpdateReminder>
                  </Grid.Column>
                  <Grid.Column>
                    <AuditInfo resource={props.selectedTherapist}></AuditInfo>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {props.eventName === Utils.EVENT_UPDATE && (
            <Button color="blue" onClick={handleUpdateButton}>
              <Icon name="pencil" /> UPDATE
            </Button>
          )}
          {props.eventName === Utils.EVENT_ADD && (
            <Button
              color="blue"
              onClick={handleAddButton}
              disabled={disableAddButton}
            >
              <Icon name="male" /> SUBMIT
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default StaffModal;
