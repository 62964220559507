import { Fragment, useContext, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Icon,
  Grid,
  Divider,
  Label,
  Input,
} from "semantic-ui-react";
import SuccessMessage from "../common/SuccessMessage";
import ErrorMessage from "../common/ErrorMessage";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import { updateCustomerPoints } from "../../api/api";

const UpdateCustomerPointsModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [hasUpdates, setHasUpdates] = useState(false);
  const [totalPoints, setTotalPoints] = useState(
    props.customer.pointsEarned ? props.customer.pointsEarned : 0
  );
  const [updatedPoints, setUpdatedPoints] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const computePoints = (points) => {
    setUpdatedPoints(points);
    let updatePoints = points ? parseFloat(points) : 0;
    let pointsEarned = parseFloat(props.customer.pointsEarned)
      ? props.customer.pointsEarned
      : 0;
    if (props.event === Utils.ADD_CUSTOMER_POINTS) {
      setTotalPoints((pointsEarned + updatePoints).toFixed(2));
    } else if (props.event === Utils.SUBTRACT_CUSTOMER_POINTS) {
      setTotalPoints((pointsEarned - updatePoints).toFixed(2));
    }
  };

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updatePoints = () => {
    if (!updatedPoints) {
      handleFormError("No points to update");
      return;
    }
    if (parseFloat(totalPoints) < 0) {
      handleFormError("Total points is less than zero");
      return;
    }

    let action;
    if (props.event === Utils.ADD_CUSTOMER_POINTS) {
      action = "ADD";
    } else if (props.event === Utils.SUBTRACT_CUSTOMER_POINTS) {
      action = "SUBTRACT";
    }

    let data = {
      storeCode: authCtx.getStoreCode(),
      action: action,
      customerMobileNumber: props.customer.mobileNumber,
      points: updatedPoints,
    };

    updateCustomerPoints(
      JSON.stringify(data),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        handleFormSuccess("Updated points");
        setHasUpdates(true);
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError(error.message);
      });
  };

  return (
    <Fragment>
      <Modal
        closeIcon
        size="tiny"
        onClose={handleOnCloseModal}
        open={props.isModalOpen}
      >
        <Modal.Header>
          {props.event === Utils.ADD_CUSTOMER_POINTS &&
            `Add Customer Points: ${props.customer.firstName} ${props.customer.mobileNumber}`}
          {props.event === Utils.SUBTRACT_CUSTOMER_POINTS &&
            `Subtract Customer Points: ${props.customer.firstName} ${props.customer.mobileNumber}`}
        </Modal.Header>
        <Modal.Content>
          {modalFormErrorMessage && (
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          )}
          {modalFormSuccessMessage && (
            <SuccessMessage
              successMessage={modalFormSuccessMessage}
            ></SuccessMessage>
          )}
          <Form id="modalUpdateCustomerPoints">
            <Grid.Row>
              <Grid.Column width={16}>
                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAvailablePoints"
                      className="borderNone paddingRight0"
                    >
                      Available Points
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop20">
                    {props.customer.pointsEarned
                      ? props.customer.pointsEarned
                      : "0"}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden fitted />

            <Grid.Row className="paddingTop20">
              <Grid.Column width={16}>
                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalUpdatedPoints"
                      className="borderNone paddingRight0 paddingTop20"
                    >
                      {props.event === Utils.ADD_CUSTOMER_POINTS &&
                        "Add Points"}
                      {props.event === Utils.SUBTRACT_CUSTOMER_POINTS &&
                        "Subtract Points"}
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop20">
                    <Input
                      id="modalUpdatedPoints"
                      max="0"
                      type="number"
                      value={updatedPoints}
                      onChange={(e) => {
                        computePoints(e.target.value);
                      }}
                    ></Input>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden fitted />

            <Grid.Row className="paddingTop20">
              <Grid.Column width={16}>
                <Grid columns={2}>
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalTotalPoints"
                      className="borderNone paddingRight0"
                    >
                      Total Points
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop20">
                    {totalPoints}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden fitted />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={updatePoints}>
            <Icon name="pencil" /> UPDATE
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default UpdateCustomerPointsModal;
