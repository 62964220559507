import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import ServicesTable from "../components/services/ServicesTable";
import Utils from "../utils/Utils";
import AuthContext from "../store/auth-context";
import ServicesModal from "../components/services/ServicesModal";
import { getServicesApi, getPromosApi, getRoomsApi } from "../api/api";

const Services = (props) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [availablePromos, setAvailablePromos] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);

  const handleOnCloseModal = (needsReload) => {
    setIsModalOpen(false);
    if (needsReload) {
      window.location.reload();
    }
  };

  const fetchServices = useCallback(async () => {
    try {
      const response = await getServicesApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedServices = [];
      for (const key in response) {
        loadedServices.push(Utils.toService(response[key]));
      }
      setAllServices(loadedServices);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchPromos = useCallback(async () => {
    try {
      const response = await getPromosApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedPromos = [];
      for (const key in response) {
        loadedPromos.push(Utils.toPromo(response[key]));
      }
      setAvailablePromos(loadedPromos);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await getRoomsApi(
        false,
        authCtx.getStoreCode(),
        authCtx.getToken()
      );

      const loadedRooms = [];
      for (const key in response) {
        loadedRooms.push(Utils.toRoom(response[key]));
      }
      setAvailableRooms(loadedRooms);
    } catch (error) {
      console.log("error: " + error);
    }
  }, [authCtx]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      fetchServices();
      fetchPromos();
      fetchRooms();
    }
  }, [fetchServices, authCtx]);

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column width={12}>
          <ServicesTable
            services={allServices}
            serviceOfferedTypes={props.serviceOfferedTypes}
            availablePromos={availablePromos}
            availableRooms={availableRooms}
          ></ServicesTable>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button color="blue" onClick={() => setIsModalOpen(true)}>
            <Icon name="add user" /> ADD
          </Button>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
      </Grid>
      {isModalOpen && (
        <ServicesModal
          isModalOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          eventName={Utils.EVENT_ADD}
          serviceOfferedTypes={props.serviceOfferedTypes}
          availablePromos={availablePromos}
          availableRooms={availableRooms}
        />
      )}
    </Fragment>
  );
};

export default Services;
