import { Fragment, useState, useContext } from "react";
import {
  Modal,
  Form,
  Grid,
  Icon,
  Button,
  Label,
  Checkbox,
  Input,
  Divider,
} from "semantic-ui-react";
import ErrorMessage from "../common/ErrorMessage";
import SuccessMessage from "../common/SuccessMessage";
import AuditInfo from "../common/AuditInfo";
import AuthContext from "../../store/auth-context";
import Utils from "../../utils/Utils";
import { addAdminApi, updateAdminApi } from "../../api/api";

const AdminsModal = (props) => {
  const authCtx = useContext(AuthContext);

  const [username, setUsername] = useState(
    props.selectedUser ? props.selectedUser.username : ""
  );
  const [enableAccess, setEnableAccess] = useState(
    props.selectedUser ? props.selectedUser.enableAccess : true
  );

  const [hasUpdates, setHasUpdates] = useState(false);
  const [modalFormSuccessMessage, setModalFormSuccessMessage] = useState("");
  const [modalFormErrorMessage, setModalFormErrorMessage] = useState("");

  const handleOnCloseModal = () => {
    props.handleOnCloseModal(hasUpdates);
  };

  const handleFormError = (errorMessage) => {
    setModalFormSuccessMessage("");
    setModalFormErrorMessage(errorMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFormSuccess = (successMessage) => {
    setModalFormErrorMessage("");
    setModalFormSuccessMessage(successMessage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleUpdateButton = (event) => {
    event.preventDefault();

    const updateData = {};

    updateData["enableAccess"] = enableAccess ? "true" : "false";

    updateAdminApi(
      username,
      JSON.stringify(updateData),
      authCtx.getStoreCode(),
      authCtx.getToken()
    )
      .then(() => {
        handleFormSuccess("Admin access updated");
        setHasUpdates(true);
      })
      .catch((error) => {
        console.log("Error:", error);
        handleFormError(error.message);
      });
  };

  const handleAddButton = (event) => {
    event.preventDefault();
    const form = document.getElementById("modalAdminForm");
    if (form) {
      const formData = new FormData(form);
      const adminData = {};
      for (let [name, value] of formData.entries()) {
        console.log(value);
        adminData[name] = value;
      }

      if (!adminData.username || adminData.username.length < 5) {
        handleFormError(
          "Username is required and should be greater than or equal to 5 characters."
        );
        return;
      }

      if (!adminData.password || adminData.password.length < 5) {
        handleFormError(
          "Password is required and should be greater than or equal to 5 characters."
        );
        return;
      }

      if (!adminData.firstName || adminData.firstName.length < 2) {
        handleFormError(
          "First name is required and should be greater than or equal to 2 characters."
        );
        return;
      }

      if (!adminData.lastName || adminData.lastName.length < 2) {
        handleFormError(
          "Last name is required and should be greater than or equal to 2 characters."
        );
        return;
      }

      const containsInvalidCharacters = Utils.INVALID_NAME_CHARACTERS.some(
        (char) => adminData.username.includes(char)
      );

      if (containsInvalidCharacters) {
        handleFormError(
          `Username contains invalid character. These characters ${Utils.INVALID_NAME_CHARACTERS.join(
            " "
          )} are not allowed.`
        );
        return;
      }

      if (adminData.username.includes(" ")) {
        handleFormError(`Username should not contain space.`);
        return;
      }

      adminData.enableAccess = enableAccess;

      adminData.roles = ["ADMIN"];

      adminData.storeCode = authCtx.getStoreCode();

      addAdminApi(
        JSON.stringify(adminData),
        authCtx.getStoreCode(),
        authCtx.getToken()
      )
        .then(() => {
          handleFormSuccess("Admin added");
          setHasUpdates(true);
        })
        .catch((error) => {
          console.log("Error:", error);
          handleFormError(error.message);
        });
    }
  };

  return (
    <Fragment>
      <Modal
        closeIcon
        size="tiny"
        onClose={handleOnCloseModal}
        open={props.isModalOpen}
      >
        {props.eventName === Utils.EVENT_UPDATE && (
          <Modal.Header>Update Admin Access: {username}</Modal.Header>
        )}

        {props.eventName === Utils.EVENT_ADD && (
          <Modal.Header>Add Admin</Modal.Header>
        )}
        <Modal.Content>
          {modalFormErrorMessage && (
            <ErrorMessage errorMessage={modalFormErrorMessage}></ErrorMessage>
          )}
          {modalFormSuccessMessage && (
            <SuccessMessage
              successMessage={modalFormSuccessMessage}
            ></SuccessMessage>
          )}

          <Form size="small" key="small" id="modalAdminForm">
            {props.eventName === Utils.EVENT_ADD && (
              <Fragment>
                <Grid columns={2} className="marginBottom10">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAdminUsername"
                      className="borderNone paddingRight0"
                    >
                      Username
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop10">
                    <Input
                      id="modalAdminUsername"
                      name="username"
                      required
                    ></Input>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} className="marginBottom10">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAdminPassword"
                      className="borderNone paddingRight0"
                    >
                      Password
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop10">
                    <Input
                      id="modalAdminPassword"
                      name="password"
                      required
                    ></Input>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} className="marginBottom10">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAdminFirstName"
                      className="borderNone paddingRight0"
                    >
                      First Name
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop10">
                    <Input
                      id="modalAdminFirstName"
                      name="firstName"
                      required
                    ></Input>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />

                <Grid columns={2} className="marginBottom10">
                  <Grid.Column width={4}>
                    <Label
                      basic
                      htmlFor="modalAdminLastName"
                      className="borderNone paddingRight0"
                    >
                      Last Name
                    </Label>
                  </Grid.Column>
                  <Grid.Column width={12} className="paddingTop10">
                    <Input
                      id="modalAdminLastName"
                      name="lastName"
                      required
                    ></Input>
                  </Grid.Column>
                </Grid>
                <Divider hidden fitted />
              </Fragment>
            )}

            <Grid columns={2} className="marginBottom10">
              <Grid.Column width={4}>
                <Label
                  basic
                  htmlFor="enableAccess"
                  className="borderNone paddingRight0"
                >
                  Enable Access
                </Label>
              </Grid.Column>
              <Grid.Column width={12} className="paddingTop10">
                <Checkbox
                  toggle
                  id="enableAccess"
                  name="enableAccess"
                  checked={enableAccess}
                  onChange={(e) => {
                    setEnableAccess(e.target.checked);
                  }}
                ></Checkbox>
              </Grid.Column>
            </Grid>
            <Divider hidden fitted />
          </Form>
          {props.eventName === Utils.EVENT_UPDATE && (
            <AuditInfo resource={props.selectedUser}></AuditInfo>
          )}
        </Modal.Content>
        <Modal.Actions>
          {props.eventName === Utils.EVENT_UPDATE && (
            <Button color="blue" onClick={handleUpdateButton}>
              <Icon name="pencil" /> Update
            </Button>
          )}
          {props.eventName === Utils.EVENT_ADD && (
            <Button color="blue" onClick={handleAddButton}>
              <Icon name="briefcase" /> SUBMIT
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default AdminsModal;
